import React from 'react'
import responsable from '../Images/footer/responsable.png'
import casinodata from '../saladata.json'

import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterContainerWrapper,
  FooterSectionLogo,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLinksContainer,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  LogoResponsable
} from './Footer.elements';

function Footer() {
  return (
    <FooterContainer>
      <FooterContainerWrapper>
        <FooterSectionLogo >
          <img src={casinodata.logo} alt="" width="110px" height="auto" style={{marginBottom:"1rem"}} />
          <p> APUESTA GOL</p>
        </FooterSectionLogo>
        <FooterLinksContainer> 
          <FooterLinkItems>
            <FooterLinkTitle>Soporte</FooterLinkTitle>
            <FooterLink to='/'>Sobre nosotros</FooterLink>
            <FooterLink to='/'>Contacto</FooterLink>
            <FooterLink to='/'>FAQs</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
          <FooterLinkTitle>APUESTA GOL</FooterLinkTitle>
            <FooterLink to='/'>Afiliados</FooterLink>
            <FooterLink to='/'>Términos y condiciones</FooterLink>
            <FooterLink to='/'>Juego responsable</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Redes Sociales</FooterLinkTitle>
            <FooterLink to='/'>Instagram</FooterLink>
            <FooterLink to='/'>Facebook</FooterLink>
            <FooterLink to='/'>Youtube</FooterLink>
          </FooterLinkItems>
        </FooterLinksContainer>

      </FooterContainerWrapper>
      
      <SocialMedia>
        <SocialMediaWrap>
          
          <LogoResponsable>
            <img src={responsable} alt="" style={{height:"3.5rem"}}></img>
          </LogoResponsable>

          <WebsiteRights>APUESTA GOL © Copyright 2024</WebsiteRights>

          <SocialIcons>
            <SocialIconLink href='/' target='_blank' aria-label='Facebook'>
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href='/' target='_blank' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href='/' target='_blank' aria-label='Youtube' >
              <FaYoutube />
            </SocialIconLink>
            <SocialIconLink href='/' target='_blank' aria-label='Twitter'>
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink href='/' target='_blank' aria-label='LinkedIn'>
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
          
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
