import Axios from 'axios'
import {Apis} from "../util/Apis";

export async function getGames() {

  try {
    return await Axios({
      url: `${Apis.BASE}/games?portalId=77`,
      method: 'GET'
    })
  } catch (e) {
    console.log(e)
  }
}

export async function getNewGames() {

  try {
    const params = {portalId: 77, tag: 'nuevos_juegos'}
    return await Axios({
      url: `${Apis.BASE}/games/recent`,
      params:params,
      method: 'GET'
    })
  } catch (e) {
    console.log(e)
  }
}

export async function getFavoriteGames() {

  try {
    const params = {portalId: 77}
    let headers = {'Authorization': 'Bearer ' + localStorage.getItem('auth')}
    return await Axios({
      url: `${Apis.BASE}/games/favorites`,
      params:params,
      headers: headers,
      method: 'GET'
      
    })
  } catch (e) {
    console.log(e)
  }
}
