import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay, Pagination} from 'swiper'
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss'
import casinodata from '../saladata.json'

SwiperCore.use([Autoplay, Pagination]);

const Slider = () => {
  return (
    <Swiper
      loop={true}
      autoplay={{
        "delay": 3000,
        "disableOnInteraction": false
      }}
      slidesPerView={1}
      pagination={{
        clickable: true
      }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >

      {casinodata.publicidad.map((item,i) =>
        <SwiperSlide key={i}><img src={item} rel="preload" alt="slider" style={{width: "100%"}}/></SwiperSlide>
      )}
    </Swiper>
  )
}

export default Slider

