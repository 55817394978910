import styled from 'styled-components'

const PageWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  padding: 1.2rem;
  background-color: #F2F0F0;
  transition: ease all .5s;
  scroll-behavior: smooth;
  @media screen and (max-width: 1100px) {
    padding: 1rem;
  }
`
export default PageWrapperStyled

