import React from 'react'
import styled from 'styled-components'

const ButtonStyled = styled.div`
  .btn {
    margin: .375rem;
    color: inherit;
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: .3rem;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    padding: .5rem 1rem;
    font-size: 0.9rem;
    display: block;
    font-weight:600;
    font-family: 'Arial';
  }
  
  .btn-xsmall{
    padding: 0.3rem .70rem;
    font-size: .8rem;
    line-height: 1;
    border-radius: .2rem;
    margin: 3px;
  }

  .btn-small{
    padding: .25rem 1rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
  }

  .btn-large{
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
  }
  
  
  .btn:hover,
  .btn:focus,
  .btn:active {
    outline: 0;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)
  }
  .btn:disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /*-- button primary --*/
  .btn-primary {
    color: #fff;
    background-color: #265E8C !important
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #265E8C
  }

  .btn-primary:focus,
  .btn-primary.focus {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)
  }

  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active {
    background-color: #265E8C
  }

  .btn-outline-primary {
    color: #265E8C !important;
    background-color: white !important;
    border: 2px solid #265E8C !important
  }

  .btn-outline-primary:hover,
  .btn-outline-primary:focus,
  .btn-outline-primary:active,
  .btn-outline-primary:active:focus,
  .btn-outline-primary.active {
    color: #265E8C !important;
    background-color: white !important;
    border-color: #265E8C !important
  }
  
  /*-- button secondary --*/
  
  .btn-secondary {
  color: #0b1b28;
  background-color: #FFAD00
  }
  
  .btn-secondary:hover {
  color: #0b1b28;
  background-color: #FFAD00
  }
  
  .btn-secondary:focus,
  .btn-secondary.focus {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)
  }
  
  .btn-secondary:focus,
  .btn-secondary:active,
  .btn-secondary.active {
  background-color: #FFAD00
  }
  
  .btn-outline-secondary {
  color: #0b1b28 !important;
  background-color: white !important;
  border: 2px solid #0b1b28 !important
  }
  
  .btn-outline-secondary:hover,
  .btn-outline-secondary:focus,
  .btn-outline-secondary:active,
  .btn-outline-secondary:active:focus,
  .btn-outline-secondary.active {
  color: #0b1b28 !important;
  background-color: white !important;
  border-color: #0b1b28 !important
  }
  
  /*-- button success --*/
  
  .btn-success {
  color: #fff;
  background-color: #268c47 !important
  }
  
  .btn-success:hover {
  color: #fff;
  background-color: #268c47
  }
  
  .btn-success:focus,
  .btn-success.focus {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)
  }
  
  .btn-success:focus,
  .btn-success:active,
  .btn-success.active {
  background-color: #268c47
  }
  
  .btn-outline-success {
  color: #00c851 !important;
  background-color: white !important;
  border: 2px solid #268c47 !important
  }
  
  .btn-outline-success:hover,
  .btn-outline-success:focus,
  .btn-outline-success:active,
  .btn-outline-success:active:focus,
  .btn-outline-success.active {
  color: #268c47 !important;
  background-color: white !important;
  border-color: #268c47 !important
  }
  
  /*-- button info --*/
  
  .btn-info {
  color: #fff;
  background-color: #33b5e5 !important
  }
  
  .btn-info:hover {
  color: #fff;
  background-color: #4abde8
  }
  
  .btn-info:focus,
  .btn-info.focus {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)
  }
  
  .btn-info:focus,
  .btn-info:active,
  .btn-info.active {
  background-color: #14799e
  }
  
  .btn-outline-info {
  color: #33b5e5 !important;
  background-color: white !important;
  border: 2px solid #33b5e5 !important
  }
  
  .btn-outline-info:hover,
  .btn-outline-info:focus,
  .btn-outline-info:active,
  .btn-outline-info:active:focus,
  .btn-outline-info.active {
  color: #33b5e5 !important;
  background-color:   whte !important;
  border-color: #33b5e5 !important
  }
  
  /*-- button warning --*/
  
  .btn-warning {
  color: #fff;
  background-color: #fb3 !important
  }
  
  .btn-warning:hover {
  color: #fff;
  background-color: #ffc44d
  }
  
  .btn-warning:focus,
  .btn-warning.focus {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)
  }
  
  .btn-warning:focus,
  .btn-warning:active,
  .btn-warning.active {
  background-color: #c80
  }
  
  .btn-outline-warning {
  tcolor: #fb3 !important;
  background-color:   whte !important;
  border: 2px solid #fb3 !important
  }
  
  .btn-outline-warning:hover,
  .btn-outline-warning:focus,
  .btn-outline-warning:active,
  .btn-outline-warning:active:focus,
  .btn-outline-warning.active {
  color: #fb3 !important;
  background-color:   whte !important;
  border-color: #fb3 !important
  }
  
  /*-- button danger --*/
  
  .btn-danger {
  color: #fff;
  background-color: #C10000 !important
  }
  
  .btn-danger:hover {
  color: #fff;
  background-color: #ff4f5e
  }
  
  .btn-danger:focus,
  .btn-danger.focus {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)
  }
  
  .btn-danger:focus,
  .btn-danger:active,
  .btn-danger.active {
  background-color: #ce0012
  }
  
  .btn-outline-danger {
  color: #C10000 !important;
  background-color:   whte !important;
  border: 2px solid #C10000 !important
  }
  
  .btn-outline-danger:hover,
  .btn-outline-danger:focus,
  .btn-outline-danger:active,
  .btn-outline-danger:active:focus,
  .btn-outline-danger.active {
  color: #C10000 !important;
  background-color:   whte !important;
  border-color: #C10000 !important
  }
  
  /*-- button light --*/
  
  .btn-light {
  color: #000;
  background-color: #e0e0e0 !important
  }
  
  .btn-light:hover {
  color: #000;
  background-color: #ededed
  }
  
  .btn-light:focus,
  .btn-light.focus {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)
  }
  
  .btn-light:focus,
  .btn-light:active,
  .btn-light.active {
  background-color: #adadad
  }
  
  .btn-outline-light {
  color: #e0e0e0 !important;
  background-color:   whte !important;
  border: 2px solid #e0e0e0 !important
  }
  
  .btn-outline-light:hover,
  .btn-outline-light:focus,
  .btn-outline-light:active,
  .btn-outline-light:active:focus,
  .btn-outline-light.active {
  color: #e0e0e0 !important;
  background-color:   whte !important;
  border-color: #e0e0e0 !important
  }
  
  /*-- button dark --*/
  
  .btn-dark {
  color: #fff;
  background-color: #212121 !important
  }
  
  .btn-dark:hover {
  color: #fff;
  background-color: #2e2e2e
  }
  
  .btn-dark:focus,
  .btn-dark.focus {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)
  }
  
  .btn-dark:focus,
  .btn-dark:active,
  .btn-dark.active {
  background-color: #000
  }
  
  .btn-outline-dark {
  color: #212121 !important;
  background-color:   whte !important;
  border: 2px solid #212121 !important
  }
  
  .btn-outline-dark:hover,
  .btn-outline-dark:focus,
  .btn-outline-dark:active,
  .btn-outline-dark:active:focus,
  .btn-outline-dark.active {
  color: #212121 !important;
  background-color:   whte !important;
  border-color: #212121 !important
  }

  .btn-block{
    display: block;
    width: 100%;
    margin:0 !important;
    height: 2.5rem;
  }
`

function Button({btnstyle, content, clicked, disabled}) {

  return (
    <ButtonStyled>
      <button className={`btn ${btnstyle}`} onClick={clicked} disabled={disabled}>
        {content}
      </button>
    </ButtonStyled>
  )
}

export default Button