import React, {useState} from 'react'
import styled from 'styled-components'
import Button from '../Components/Button'
import { Field,Control,Label,Input,TextMuted } from '../Components/Form'
import ListCountrys from '../util/country_prefix.json'
import casinodata from '../Components/saladata.json'

const SignInBackground = styled.div`
  max-width: 600px;
  margin: 1rem auto;
  border-radius:10px;
  -webkit-box-shadow: 0 0 10px 5px rgb(0 0 0 / 25%);
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 25%);
  padding: 2rem 3rem;
  background-color:white;
`

const CustomCheckbox = styled(Input)`
  width: auto !important;
  display: inline !important;
  height: auto  !important;
  margin-right: 0.5rem  !important;
`
  

const SigninPage = () => {

  const [country, setCountry] = useState(null)
  const [prefix, setPrefix] = useState("+")

  function handleChangeCountry(e){
    const selectPrefix=e.target.value;
    setCountry(selectPrefix);
    setPrefix(selectPrefix);
 }

  return (
    <div style={{backgroundColor: "whitesmoke", padding:"2rem"}}>
      <SignInBackground>

        <div style={{textAlign:"center"}}>
          <img src={casinodata.logo} alt="" width="auto" height="120"/>
        </div>

        <div style={{margin:"2rem 0"}}>
          <Button content='Ingresa si ya tienes una cuenta' btnstyle='btn-secondary btn-block' />
        </div>

        <form style={{display:"flex",flexDirection:"column"}}>

          <Field>
            <Control>
              <Label>Nombre de usuario</Label>
              <Input type="text"/>
              <TextMuted>Ingrese el nombre de usuario</TextMuted>
            </Control>
          </Field>

          <Field>
            <Control>
              <Label>Correo Electrónico</Label>
              <Input type="email"/>
              <TextMuted>Ingrese su correo</TextMuted>
            </Control>
          </Field>

          <Field>
            <Control>
              <Label>Nombres</Label>
              <Input type="text"/>
              <TextMuted>Ingrese sus nombres</TextMuted>
            </Control>
          </Field>

          <Field>
            <Control>
              <Label>Apellidos</Label>
              <Input type="text"/>
              <TextMuted>Ingrese sus aéllidos</TextMuted>
            </Control>
          </Field>

          <Field>
            <Control>
              <Label>Fecha de Nacimiento</Label>
              <Input type="date"/>
              <TextMuted>dd/mm/yyyy</TextMuted>
            </Control>
          </Field>

          <Field>
            <Control>
              <Label>Género</Label>
              <Input as="select">
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </Input>
              <TextMuted>Seleccione</TextMuted>
            </Control>
          </Field>

          <Field>
            <Control>
              <Label>Moneda</Label>
              <Input as="select">
                <option value="pen">Soles</option>
                <option value="dollar">Dolares</option>
              </Input>
              <TextMuted>Seleccione</TextMuted>
            </Control>
          </Field>
          
          <Field>
            <Control>
              <Label>Pais</Label>
              <Input as="select" onChange={handleChangeCountry} value={country} sele>
                {ListCountrys.map(list => <option key={list.name} value={list.dial_code}>{list.name}</option>)}
              </Input>
              <TextMuted>Seleccione pais</TextMuted>
            </Control>
          </Field>

          <Field>
            <Control>
              <Label>Provincia</Label>
              <Input type="text"/>
              <TextMuted>Ingrese provincia</TextMuted>
            </Control>
          </Field>
          
          <Field>
            <Control>
              <Label>Ciudad</Label>
              <Input type="text"/>
              <TextMuted>Ingrese su ciudad</TextMuted>
            </Control>
          </Field>

          <Field>
            <Control>
              <Label>Dirección</Label>
              <Input type="text"/>
              <TextMuted>Ingrese su dirección</TextMuted>
            </Control>
          </Field>

          <Field>
            <Control>
              <Label>Número de Celular</Label>
              <div style={{display:'flex',flexDirection:"row"}}>
                <Input type="text" style={{width:"20%"}} value={prefix} disabled/>
                <Input type="text"/>
              </div>
              <TextMuted>Ingrese su número de celular</TextMuted>
            </Control>
          </Field>
          
          <Field>
            <Control style={{display:"flex"}}>
            <CustomCheckbox type="checkbox" id="promo"/>
            <Label for="promo" style={{display:"inline",fontWeight:"normal", cursor:"pointer"}}>Me gustaría recibir el Bono de Bienvenida y promociones vía sms, teléfono y correo</Label>
            </Control>
          </Field>

          <Field>
            <Control style={{display:"flex"}}>
              <CustomCheckbox type="checkbox" id="mayor18"/>
              <Label for="mayor18" style={{display:"inline",fontWeight:"normal", cursor:"pointer"}}>Soy mayor de 18 años y acepto los <a href="/" style={{color:"inherit",fontWeight:"bold"}} >Terminos y condiciones</a> y la <a href="/"  style={{color:"inherit",fontWeight:"bold"}}>Politica de privacidad</a> </Label>
            </Control>
          </Field>
          <div  style={{margin:"2rem 0"}}>
           <Button content='REGISTRATE' btnstyle='btn-secondary btn-block'/>
          </div>
        </form>
      </SignInBackground>
    </div>
  )
}

export default SigninPage