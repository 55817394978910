import React, {useEffect, useState} from "react";
import {Redirect} from "react-router";
import {useWauth} from "../auth/WauthProvider";

const CallbackPage = () => {

  const {handleAuthentication, isAuthenticated} = useWauth()
  const [message, setMessage] = useState("Verificando cuenta")

  let referer = '/'

  useEffect(() => {
    handleAuthentication() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (isAuthenticated) {
    const redirectTo = localStorage.getItem('redirect')
    if (redirectTo !== undefined && redirectTo !== '') {
      referer = redirectTo
    }
    return <Redirect to={referer}/>
  }

  return (
    <div>
      {message}
    </div>
  )
};

export default CallbackPage;
