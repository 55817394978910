import React, {useState,useEffect} from 'react'
import styled from 'styled-components'
import * as FaIcons from 'react-icons/fa'

export const ArrowTop = styled.button`
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #c10000;
  color: white;
  cursor: pointer;
  padding: 15px 15px 12px 15px;
  border-radius: 10px;
  box-shadow: 4px 4px 5px 0px rgb(0 0 0 / 50%), 0 2px 10px 0 rgb(0 0 0 / 50%);
  svg{
    font-size:1.3rem;
  }
`

const ScrollTop = () => {

  const [isVisible, setIsVisible] = useState(false);
  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 200,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <>
      { isVisible &&  <ArrowTop onClick={scrollToTop} ><FaIcons.FaArrowUp /></ArrowTop> }
    </>
  )
}

export default ScrollTop