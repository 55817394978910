import jwt_decode from "jwt-decode"
import myAuthApi from '../myAuthApi'
import {Apis} from "../../util/Apis";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const loginUser = (username, password, connection, dispatch, setError, setLoading) => { //login

  const TOKEN_AUTH_USERNAME = process.env.REACT_APP_AUTH_CLIENT_ID
  const TOKEN_AUTH_PASSWORD = '123456'
  const TENANT = 'wgtcustomer'
  //const connection = 'dreams' //should be  dynamic

  const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&tenant=${TENANT}&connection=${connection}`;

  let config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD)
    }
  };

  setLoading(true);
  myAuthApi.post(Apis.BASE + '/oauth/token', body, config).then(res => {
    if (res.status === 200) {
      setLoading(false)
      console.log('todo correcto ok')
      const token = res.data.access_token;
      localStorage.setItem("auth", token);
      const decodedToken = jwt_decode(token);
      setError("")
      dispatch(setCurrentUser(decodedToken));
    }
  }).catch(err => {
    setLoading(false)
    console.log('hubo un error en auth : ' + err)
    setError("Credenciales invalidas")
    //setError(err.message)
  });

};

export const logoutUser = (dispatch) => {//logout
  localStorage.removeItem("auth");
  //localStorage.clear();
  dispatch(setCurrentUser({}));
};

export const setCurrentUser = decodedToken => {//si se loguea , setear datos del usuario
  return {
    type: SET_CURRENT_USER,
    payload: decodedToken
  };
};
