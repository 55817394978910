import {Switch, Route, BrowserRouter} from 'react-router-dom'
import './App.css'
import GlobalStyle from './globalStyles'
import GamesLoader from './pages/GamesLoader'
import Main from './pages/Main'
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import LanguageDetector from 'i18next-browser-languagedetector'

import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"
import global_ch from "./translations/ch/global.json"
import global_fr from "./translations/fr/global.json"
import WauthProvider from "./auth/WauthProvider";
import CallbackPage from "./pages/CallbackPage";
import SigninPage from './login/SigninPage'
import {datadogRum} from "@datadog/browser-rum";


const options = {
  // order and from where user language should be detected
  order: ['querystring', 'localStorage', 'sessionStorage', 'navigator', 'cookie', 'path', 'subdomain'],
  // /?lng=es
  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',


  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'none' }
}

i18next.use(LanguageDetector).init({

  interpolation: { escapeValue:false},
  detection: options,
  resources:{
    es:{
      global:global_es
    },
    en:{
      global:global_en
    },
    ch:{
      global:global_ch
    },
    fr:{
      global:global_fr
    }
  }
})

datadogRum.init({
  applicationId: 'fbb27168-ec24-4d1b-af8f-187ac60c350b',
  clientToken: 'pubec9c913539253ea5a0ff0fbc8a8f6f32',
  site: 'datadoghq.com',
  service:'front-casino-online',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  allowedTracingOrigins: ["https://apuestagol.dev", "https://api4.wargos.cloud", "https://api5.wargos.cloud", "https://sloti.wargos.cloud"]
});

Notification.requestPermission(function(result) {
  if (result === 'denied') {
    console.log('Permission wasn\'t granted. Allow a retry.');
    return;
  } else if (result === 'default') {
    console.log('The permission request was dismissed.');
    return;
  }
  // Hacer algo con el permiso concedido.
});

if (Notification.permission == 'granted') {
  navigator.serviceWorker.getRegistration().then(reg => {

    const options = {
      body: 'Casino les da la bienvenida',
      icon: './Components/Images/icons/slot-machine.svg',
      vibrate: [100, 50, 100],
      data: {
        dateOfArrival: Date.now(),
        primaryKey: 1
      },
    
      actions: [
        {action: 'explore', title: 'Nada',
          icon: 'images/checkmark.png'},
        {action: 'close', title: 'Cerrar',
          icon: 'images/xmark.png'},
      ]
    
      // TODO 5.1 - add a tag to the notification
    
    };

    reg.showNotification('Bienvenido!', options);
  });
}

function App() {
  return (
    <>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <WauthProvider
            domain={process.env.REACT_APP_AUTH_SERVER}
            clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
            redirectUri={window.location.origin + '/callback'}
          >
            <GlobalStyle />
            <Switch>
              <Route path='/games-loader/:id/:favorite' component={GamesLoader}/>
              <Route path='/callback' component={CallbackPage}/>
              <Route path='/registro'component={SigninPage}/>
              <Route path='/' component={Main}/>
            </Switch>
          </WauthProvider>
        </BrowserRouter>
      </I18nextProvider>
    </>
  )
}
export default App
