import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Nav = styled.nav`
  background: #F2F0F0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  color:#0B1B28;
  -webkit-box-shadow: 0 4px 10px 2px rgb(0 0 0 / 25%);
  box-shadow: 0 4px 10px 2px rgb(0 0 0 / 25%);
  width:100%;
  z-index: 100;
`

export const NavLogo = styled(Link)`
  color:#0B1B28;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  width: 180px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:1.5rem;
  img{
    height:75px;
  }

  @media screen and (max-width: 1100px) {
    transition: ease all .5s;
    width: 160px;
    img{
      height:65px;
    }
  }

  @media screen and (max-width: 960px) {
    transition: ease all .5s;
    img{
      height:55px;
    }
  }
  @media screen and (max-width: 425px) {
    transition: ease all .5s;
    img{
      height:45px;
    }
  }
`
export const MenuMobile = styled.div`
  display: none;

  @media screen and (max-width: 1100px) {
    width:100%;
    display:flex;
    align-items: center;
    justify-content:flex-end;

  }
`

export const MobileIcon = styled.div`
  font-size: 1.8rem;
  cursor: pointer; 
  display: flex;
  align-items:center;
  margin-left:0.5rem;
`

export const NavMenuContent= styled.div`
    display: flex;
    width: calc(100% - 180px);
    justify-content: space-between;
    align-items: center;
`

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 63px;
    right: ${({ click }) => (click ? 0 : '-100%')};
    opacity: 0.95;
    transition: all 0.5s ease;
    background: #F2F0F0;
    justify-content: flex-start;
    margin-right: 0;
  }
  @media screen and (max-width: 960px) {
    top: 53px;
  }
  @media screen and (max-width: 425px) {
    top: 43px;     
  }
`

export const NavItem = styled.div`
    display:flex;
    align-items:center;
    
    flex-direction: column;
    justify-content:center;
    border-bottom: 2px solid transparent;
    padding: 0.5rem 1rem;
    position:relative;
    &:hover {
      border-bottom: 2px solid #C10000;
      }
    }
    a{
      color: #0B1B28;
      text-decoration:none;
      
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
      justify-content:center;
      flex-direction: row;
      font-size:1.5rem;
    }
`
export const NavItemImg = styled.img`
    margin-bottom:0.2rem;
    width:35px;
    height:35px; 
    
    @media screen and (max-width: 1100px) {
      margin-bottom:0rem;
      margin-right:0.2rem;
    }
`
export const NavLinks = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  img{
    margin-right:0.5rem;
  }

  @media screen and (max-width: 1100px) {
    justify-content:center;
    flex-direction: row;
    padding: 0.5rem 1rem;
    img{
      margin-right:0.5rem;
    }
    &:hover {
      color: #265377;
      transition: all 0.3s ease;
    }
    
  }
`
export const NavMobileBtn = styled.div`
  display:none;
  @media screen and (max-width: 1100px) {
    display:flex;
    flex-direction:row;
    justify-content:center;
  }
`
export const MobileMenuButton = styled.div`
  display: flex;
  margin-right: 0.6rem;
  align-items: center;
  
  @media screen and (max-width: 1100px) {
    display:none;
  }
`
export const NavMobilePerfil = styled.div`
  display:none;
  @media screen and (max-width: 1100px) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    font-size:1.3rem;
    margin-bottom:1rem;
    color:#c10000;
    width:100%;
  }
`
export const NavRightMenu = styled.div`
  display: flex;
  margin-right: 0.6rem;
  align-items: center;
  
  @media screen and (max-width: 1100px) {
    display:none;
  }
`
export const ImgPerfil =styled.img`
  width:45px;
`
export const SaldoUsuario =styled.div`
  display:flex;
  justify-content:space-between;
  flex-direction:row;
  margin-top:1rem;
  width:70%;
`
export const NavRightSaldo =styled.div`
  padding:0.6rem;
  font-size:1.3rem;
  cursor:pointer;
  text-align:center;
  white-space: nowrap;
`

export const NavSalir =styled.div`
  padding:0.6rem;
  cursor:pointer;
`

export const NavLng =styled.div`
  color:#0B1B28;
  display:flex;
  flex-direction:column;
  padding:0.3rem;
  align-items:center;
  select{
    padding:0.2rem;
    margin-top:0.2rem;
    font-family: 'Lilita One', cursive;
  }
`