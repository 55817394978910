import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
import Button from '../Components/Button'
import axios from "axios"
import {Apis} from "../util/Apis"
import {useTranslation} from "react-i18next"
import {ModalHeader, ModalTitle, ModalFooter} from "../Components/Modal"
import * as FaIcons from 'react-icons/fa'

import logovisamastercard from '../Components/Images/payments/logovisa.png'

export const VisaTitulo = styled.div`
  width: 100%;
  padding: 0 2rem 2rem;
  text-align: center;
  img{
    height:70px;
  }
  @media screen and (max-width: 580px) {
    padding: 0 0.5rem 0.5rem;
    img{
      height:60px;
    }
  }
`
export const SelectMonto = styled.div`
  display:flex;
  border:1px solid lightgrey;
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 5px 0px, rgb(0 0 0 / 12%) 0px 2px 10px 0px;
  border-radius:5px;
  
  @media screen and (max-width: 580px) {

    }
`
export const BloqueMonto = styled.div`
  border-right:1px solid lightgrey;
  text-align:center;
  padding:0.5rem;
  cursor:pointer;
  width:25%;
  ${({active}) => active && `
    color: #0B1B28;
    background: lightgrey;      
  `}

  &:hover {
    color: #0B1B28;
    background: lightgrey;
  }

  &.active {
    color: #0B1B28;
    background: lightgrey;
  }
  @media screen and (max-width: 580px) {
    }

`

export const BloqueInput = styled.div`
  padding:0.5rem;
  width:25%;
  input{
    border:0;
    margin:0;
    height:100%;
    width:100%;
    text-align:center;
    background:transparent;
  }
  
  @media screen and (max-width: 580px) {
    }
`

export const ShowMonto = styled.div`
  display:flex;
  width:100%;
  justify-content:center;
  align-items:center;
  margin-top:1rem;
  p{
    font-family:Open Sans;
  font-size:1.3rem;
  }
  
  @media screen and (max-width: 580px) {
    }
`
export const ContenidoCheckbox = styled.div`
  display:flex;
  width:100%;
  justify-content:center;
  align-items:center;
  margin:1rem;
  
  @media screen and (max-width: 580px) {
    }
`
export const MetodoBoton = styled.div`
  display:flex;
  width:100%;
  justify-content:space-around;
  align-items:center;
  
  @media screen and (max-width: 580px) {
    }
`

const PagoVisa = ({cambiarMetodo,modalRecarga}) => {

  const [t] = useTranslation("global")

  const [active20, setActive20] = useState(false)
  const [active50, setActive50] = useState(false)
  const [active100, setActive100] = useState(false)
  const [monto, setMonto] = useState(0)

  const onClickChange20 = () => {
    setActive20(true)
    setActive50(false)
    setActive100(false)
    setMonto(20)
  }
  const onClickChange50 = () => {
    setActive50(true)
    setActive20(false)
    setActive100(false)
    setMonto(50)
  }
  const onClickChange100 = () => {
    setActive100(true)
    setActive20(false)
    setActive50(false)
    setMonto(100)
  }

  const montoPersonalizado = event => {
    setMonto(event.target.value)
    setActive20(false)
    setActive50(false)
    setActive100(false)
  };

  const [dataValues, setDataValues] = useState([])
  const loadData = () => {
    axios.get(Apis.BASE + '/me', { responseType: "json",
      headers: { 'Authorization': 'Bearer '+localStorage.getItem('auth') }
    }).then(res => {
      setDataValues(res.data)
    }).catch(err => {
      console.log('hubo un error')
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      <ModalHeader className="modal-header">
        <ModalTitle>{t("pagovisa.titulo")}</ModalTitle>
        <FaIcons.FaTimes onClick={() => modalRecarga.current.close()}/>
      </ModalHeader>
      <div className="modal-body">
        <VisaTitulo>
          <img src={logovisamastercard} alt=""></img>
        </VisaTitulo>
        <SelectMonto>
          <BloqueMonto active={active20} onClick={()=>onClickChange20()}>{dataValues.currencySymbol} 20</BloqueMonto>
          <BloqueMonto active={active50} onClick={()=>onClickChange50()}>{dataValues.currencySymbol} 50</BloqueMonto>
          <BloqueMonto active={active100} onClick={()=>onClickChange100()}>{dataValues.currencySymbol} 100</BloqueMonto>
          <BloqueInput>
            <input type="number" placeholder='otro' onChange={montoPersonalizado}></input>
          </BloqueInput>
        </SelectMonto>
        <ShowMonto>
          <p>{t("pagovisa.depositar")} {dataValues.currencySymbol} {monto} {dataValues.currency}</p>
        </ShowMonto>
        <ContenidoCheckbox>
          <label className="b-contain">
            <span>{t("pagovisa.condiciones")}</span>
            <input type="checkbox"/>
            <div className="b-input"></div>
          </label>
        </ContenidoCheckbox>
      </div>
      <ModalFooter>
        <MetodoBoton>
          <Button content={t("pagovisa.cambiar")} btnstyle='btn-danger' clicked={()=>cambiarMetodo('default')}/>
          <Button content='Aceptar' btnstyle='btn-secondary' clicked={() => modalRecarga.current.close()} disabled={monto<20}/>
        </MetodoBoton>
      </ModalFooter>
    </>
  )
}

export default PagoVisa
