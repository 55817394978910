export const bankData =[
  {
    "banco": "PRINCIPAL A",
    "cliente": "Cliente Cliente",
    "accNumber": "1234 4321 1234 4321",
    "currency": "PEN"
  },
  {
    "banco": "PRINCIPAL B",
    "cliente": "Cliente Cliente",
    "accNumber": "4567 4321 5678 9876",
    "currency": "PEN"
  }
]