import React, { useState } from 'react'
import ruleta from '../Components/Images/icons/ruleta.svg'
import poker from '../Components/Images/icons/poker.svg'
import blackjack from '../Components/Images/icons/blackjack.svg'

import { useTranslation } from "react-i18next"
import GamesBlock from "./GamesBlock"
import AllGamesBlock from "./AllGamesBlock"
import {
  Container,
  WrapperFilter,
  ContentFilter,
  ListFilter,
  Itemfilter,
  ItemImg,
  Input
} from "./Tragamonedas"
import ScrollTop from '../Components/ScrollTop'

const Mesas = () => {

  const [filtro, setFiltro] = useState(false);
  const [t]=useTranslation("global")

  const stickyFiltro = () => {
    if (window.scrollY >= 90) {
      setFiltro(true)
    } else {
      setFiltro(false)
    }
  }
  window.addEventListener('scroll', stickyFiltro)

  const [showCategory, setShowCategory] = useState(null)
  const categoryClicked = (category) =>{
    setShowCategory(category)
  }

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <ScrollTop/>
      
      <WrapperFilter sticky={filtro}>
        <ContentFilter>
          <ListFilter>
            <Itemfilter onClick={() => categoryClicked('ruleta')}>
              <ItemImg src={ruleta} alt="ruleta"/>
              {t("filtros.ruleta")}
            </Itemfilter>
            <Itemfilter onClick={() => categoryClicked('poker')}>
              <ItemImg src={poker} alt="poker"/>
              {t("filtros.poker")}
            </Itemfilter>
            <Itemfilter onClick={() => categoryClicked('blackjack')}>
              <ItemImg src={blackjack} alt="blackjack"/>
              {t("filtros.blackjack")}
            </Itemfilter>
            
          </ListFilter>
          <Input type='text' placeholder={t("filtros.buscar")} onChange={(event)=>{setSearchTerm(event.target.value)}}/>

        </ContentFilter>
      </WrapperFilter>

      {
        (() => {
          switch (showCategory) {
            case 'ruleta':
              return ( 
                  <GamesBlock key="ruleta" title={t("filtros.ruleta")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'poker':
              return ( 
                  <GamesBlock key="poker" title={t("filtros.poker")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'blackjack':
              return ( 
                  <GamesBlock key="blackjack" title={t("filtros.blackjack")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            default:
              return (
                <Container>
                  <AllGamesBlock title={t("filtros.ruleta")} tag={'ruleta'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.poker")} tag={'poker'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.blackjack")} tag={'blackjack'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                </Container>
              )
          }
        })()
      }
    </>
  )
}
export default Mesas
