import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useParams} from 'react-router-dom'
import Modal from "../Components/Modal"
import * as FaIcons from 'react-icons/fa'
import axios from "axios"
import {Apis} from "../util/Apis"
import {useWauth} from "../auth/WauthProvider"
import {FullScreen, useFullScreenHandle} from "react-full-screen"
import MetodoPago from './MetodoPago'

import home from '../Components/Images/icons/home.svg'
import enterFullscreen from '../Components/Images/icons/enter-fullscreen.svg'
import exitFullscreen from '../Components/Images/icons/exit-fullscreen.svg'
import coins from '../Components/Images/icons/monedas.svg'
import useQueryParam from "../hooks/useQueryParam";

export const GamesLoaderBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #2d6592fa; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #2d6592fa, #9aa4af); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #2d6592fa, #9aa4af); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-position: center center;
  z-index: 98;
  width: 100%;
  height: 100vh;
`
export const ContentGamesLoader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 71vw;
  height: 90%;
  z-index: 99;
  background-color: #0000006e;
  -webkit-box-shadow: 0 0 20px 5px rgb(0 0 0 / 50%);
  box-shadow: 0 0 20px 5px rgb(0 0 0 / 50%);
  border-radius: 3px;
  transition: ease all .5s;
  @media screen and (max-width: 1200px) {
    transition: ease all .5s;
    width: 85vw;
    height: 93%;
  }
  @media screen and (max-width: 960px) {
    transition: ease all .5s;
    width: 90vw;
    height: 96%;
  }
  @media screen and (max-width: 425px) {
    width: 100vw;
    height: 100%;
  }

`
export const LoaderHeader = styled.div`
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 3px 3px 0 0;
  color: white;
  padding: 0.5rem 0.5rem 0 0.5rem;
  @media screen and (max-width: 768px) {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  @media screen and (max-width: 425px) {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
`

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
`

export const HeaderCenter = styled.div`
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`

export const IconHome = styled.a`
  margin: 0 10px;

  img {
    height: 30px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`
export const IconHeart = styled.div`
  margin: 0 10px;
  font-size: 30px;
  height: 34px;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`
export const HeaderGameName = styled.p`
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 0.8rem;
`
export const HeaderClock = styled.div`
  display: flex;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 0.8rem;
  align-items: center;

  p {
    margin-left: 5px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`
export const HeaderFullscreen = styled.div`
  margin: 0 10px;

  img {
    height: 30px;
  }
`

export const IconClose = styled.a`
  margin: 0 10px;
  font-size: 35px;
  text-decoration: none;
  color: inherit;
  height: 40px;
`
export const IconRecarga = styled.div`
  margin: 0 10px;
  cursor: pointer;

  img {
    height: 30px;
  }
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: 0 0 5px 5px;

  body {
    background: transparent;
    background-color: transparent;
  }
`
export const CheckboxStyled = styled.input`
  margin: 0.5rem 0;
  position: relative;
  width: 60px;
  height: 20px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
  border: none;
  color: black;
  font-family: 'Lilita One', cursive;

  &:checked {
    background: #c10000;
  }

  &:before {
    content: 'FUN';
    position: absolute;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    top: -1;
    left: 0;
    color: black;
    background: #fff;
    transform: scale(1.1);
    transition: .5s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .9);
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &:checked {
    &:before {
      content: 'REAL';
      left: 20px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
    }
  }

`

function GamesLoader() {

  const {id} = useParams()
  const {favorite} = useParams()
  const [mode, setMode] = useQueryParam('mode', 'fun');

  const {isAuthenticated, loginWithRedirect} = useWauth()

  localStorage.removeItem('redirect')

  const [playGamesValues, setPlayGamesValues] = useState([])

  const playGames = (playMode) => {
    const params = {portalId: 77, mode: playMode}
    let headers = {}
    if (isAuthenticated) {
      headers = {'Authorization': 'Bearer ' + localStorage.getItem('auth')}
    }

    axios.get(Apis.BASE + `/games/${id}/launch`, {params: params, headers: headers}).then(res => {
      setPlayGamesValues(res.data)
    }).catch(err => {
      console.log('hubo un error al cargar juego')
    })
  }

  useEffect(() => {
    playGames(mode) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // cargar lista de juegos favoritos
  const [favoriteList, setFavoriteList] = useState([])

  const getFavoriteList = () => {
    const params = {portalId: 77}
    let headers = {}
    if (isAuthenticated) {
      headers = {'Authorization': 'Bearer ' + localStorage.getItem('auth')}
    }
    axios.get(Apis.BASE + `/games/favorites`, {params: params, headers: headers}).then(res => {
      setFavoriteList(res.data)
    }).catch(err => {
      console.log(err)
    })
  }


  //marcar como favorito un juego
  const isFavorite = () => {
    const data = {gameId: id}
    let headers = {}
    if (isAuthenticated) {
      headers = {'Authorization': 'Bearer ' + localStorage.getItem('auth')}
    }
    axios.post(Apis.BASE + `/games/favorites`, data, {headers: headers}).then(res => {
      getFavoriteList()
    }).catch(err => {
      console.log(err)
    })
  }

  //eliminar el juego favorito
  const deleteFavorite = () => {
    const data = {gameId: id}
    let headers = {}
    if (isAuthenticated) {
      headers = {'Authorization': 'Bearer ' + localStorage.getItem('auth')}
    }
    axios.delete(Apis.BASE + `/games/favorites`, {data: data, headers: headers}).then(res => {
      getFavoriteList()
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    if (!isAuthenticated && mode === 'real') {
      const pathname = window.location.pathname
      const queryParam = '?mode=real'
      localStorage.setItem('redirect', pathname + queryParam)
      loginWithRedirect();
    } else {
      playGames(mode)
    }
  }, [mode])


  const handleModeGame = () => {
    if (mode === 'real') {
      setMode('fun')
    } else {
      setMode('real')
    }
  }

  //manejar favorito
  const [favorito, setFavorito] = useState(JSON.parse(favorite))

  function toggle() {
    setFavorito(!favorito)
    if (!favorito === false) {
      deleteFavorite()
    } else {
      isFavorite()
    }
  }

  // manejar reloj
  const [time, setTime] = useState(new Date())
  useEffect(() => {
    var timer = setInterval(() => setTime(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  // manejar fullscreen
  const handle = useFullScreenHandle();

  const [existBackground] = useState("");

  const modalRecarga = useRef();
  const openModalRecarga = () => {
    modalRecarga.current.openModal()
  };

  return (
    <>
      {existBackground === playGamesValues.backgroundUrl ?
        <FullScreen handle={handle}>
          <GamesLoaderBackground>
            <ul className="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <ContentGamesLoader>
              <LoaderHeader>
                <HeaderLeft>
                  <IconHome href='/'>
                    <img src={home} alt=""/>
                  </IconHome>
                  <IconHeart>
                    {isAuthenticated ?
                      <>
                        {favorito ?
                          <FaIcons.FaHeart fill={'#C10000'} onClick={toggle}/> :
                          <FaIcons.FaRegHeart onClick={toggle}/>
                        }
                      </>
                      :
                      <></>
                    }
                  </IconHeart>
                  <HeaderGameName>
                    {playGamesValues.gameName}
                  </HeaderGameName>
                </HeaderLeft>
                <HeaderCenter>
                  <p>APUESTA GOL CASINO</p>
                  <div onClick={handleModeGame}>
                    <CheckboxStyled checked={mode === 'real'} type="checkbox" onChange={() => {
                    }}/>
                  </div>
                </HeaderCenter>

                <HeaderRight>
                  <HeaderClock>
                    <FaIcons.FaRegClock/>
                    <p>
                      {time.toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: 'numeric',
                        second: "numeric",
                        hour12: true
                      })}
                    </p>
                  </HeaderClock>
                  <IconRecarga onClick={() => {
                    openModalRecarga()
                  }}>
                    <img src={coins} alt=""/>
                  </IconRecarga>
                  <HeaderFullscreen>
                    {handle.active ?
                      <div onClick={handle.exit}><img src={exitFullscreen} alt=""></img></div>
                      :
                      <div onClick={handle.enter}><img src={enterFullscreen} alt=""></img></div>
                    }
                  </HeaderFullscreen>
                  <IconClose href='/'>
                    <FaIcons.FaTimes/>
                  </IconClose>
                </HeaderRight>
              </LoaderHeader>

              <Iframe src={playGamesValues.url} frameBorder="0"/>
            </ContentGamesLoader>
          </GamesLoaderBackground>
        </FullScreen>

        :
        <FullScreen handle={handle}>
          <GamesLoaderBackground style={{backgroundImage: `url(${playGamesValues.backgroundUrl})`}}>
            <ContentGamesLoader>
              <LoaderHeader>
                <HeaderLeft>
                  <IconHome href='/'>
                    <img src={home} alt="" height="20"/>
                  </IconHome>
                  <IconHeart>
                    {isAuthenticated ?
                      <>
                        {favorito ?
                          <FaIcons.FaHeart fill={'#C10000'} onClick={toggle}/> :
                          <FaIcons.FaRegHeart onClick={toggle}/>
                        }
                      </>
                      :
                      <></>
                    }
                  </IconHeart>
                  <HeaderGameName>
                    {playGamesValues.gameName}
                  </HeaderGameName>
                </HeaderLeft>
                <HeaderCenter>
                  <p>APUESTA GOL CASINO</p>
                  <div onClick={handleModeGame}>
                    <CheckboxStyled checked={mode === 'real'} type="checkbox" onChange={() => {
                    }}/>
                  </div>
                </HeaderCenter>

                <HeaderRight>
                  <HeaderClock>
                    <FaIcons.FaRegClock/>
                    <p>
                      {time.toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: 'numeric',
                        second: "numeric",
                        hour12: true
                      })}
                    </p>
                  </HeaderClock>
                  <IconRecarga onClick={() => {
                    openModalRecarga()
                  }}>
                    <img src={coins} alt="" height="20"/>
                  </IconRecarga>
                  <HeaderFullscreen>
                    {handle.active ?
                      <div onClick={handle.exit}><img src={exitFullscreen} alt="" height="20"></img></div>
                      :
                      <div onClick={handle.enter}><img src={enterFullscreen} alt="" height="20"></img></div>
                    }
                  </HeaderFullscreen>
                  <IconClose href='/'>
                    <FaIcons.FaTimes/>
                  </IconClose>
                </HeaderRight>
              </LoaderHeader>

              <Iframe src={playGamesValues.url} frameBorder="0"/>
            </ContentGamesLoader>
          </GamesLoaderBackground>
        </FullScreen>
      }

      <Modal ref={modalRecarga}>
        <MetodoPago modalRecarga={modalRecarga}/>
      </Modal>
    </>
  )
}

export default GamesLoader
