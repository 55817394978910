import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;

	} 

	/* width */
	*::-webkit-scrollbar {
	width: 8px;
	height:5px;
	}

	/* Track */
	*::-webkit-scrollbar-track {
	background: #f1f1f1; 
	}
	
	/* Handle */
	*::-webkit-scrollbar-thumb {
	background: #888888d1; 
	}

	/* Handle on hover */
	*::-webkit-scrollbar-thumb:hover {
	background: #555; 
	}
	

	body{
		font-family: 'Lilita One', cursive;
	}

	a{
		text-decoration: none;
	}

	input,
	select {
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	textarea{
		height:auto !important;
	}
	label {
		display: inline-block;
		margin-bottom: .5rem;
	}

	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
		margin: 0; 
	}
	input[type=number] { -moz-appearance:textfield; }
	input[type=date] {height: 42px;}

	input:focus,
	select:focus, textarea:focus{
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline:none !important;
		outline-width: 0 !important;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}
	
	
	.form-control-lg {
		height: calc(1.5em + 1rem + 2px);
		padding: .5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: .3rem;
	}

	.form-control-sm {
		height: calc(1.5em + .5rem + 2px);
		padding: .25rem .5rem;
		font-size: .875rem;
		line-height: 1.5;
		border-radius: .2rem;
	}

	.text-muted{
		color: #6c757d;
	}
	
	.text-center{
		text-align: center;
	}

	.w-25 {
		width: 25% !important
	}

	.w-50 {
		width: 50% !important
	}

	.w-75 {
		width: 75% !important
	}

	.w-100 {
		width: 100% !important
	}

	.w-auto {
		width: auto !important
	}

	.h-25 {
		height: 25% !important
	}

	.h-50 {
		height: 50% !important
	}

	.h-75 {
		height: 75% !important
	}

	.h-100 {
		height: 100% !important
	}

	.h-auto {
		height: auto !important
	}

	.mw-100 {
		max-width: 100% !important
	}

	.mh-100 {
		max-height: 100% !important
	}

	.min-vw-100 {
		min-width: 100vw !important
	}

	.min-vh-100 {
		min-height: 100vh !important
	}

	.vw-100 {
		width: 100vw !important
	}

	.vh-100 {
		height: 100vh !important
	}

	.m-0 {
		margin: 0 !important
	}

	.mt-0,
	.my-0 {
		margin-top: 0 !important
	}

	.mr-0,
	.mx-0 {
		margin-right: 0 !important
	}

	.mb-0,
	.my-0 {
		margin-bottom: 0 !important
	}

	.ml-0,
	.mx-0 {
		margin-left: 0 !important
	}

	.m-1 {
		margin: .25rem !important
	}

	.mt-1,
	.my-1 {
		margin-top: .25rem !important
	}

	.mr-1,
	.mx-1 {
		margin-right: .25rem !important
	}

	.mb-1,
	.my-1 {
		margin-bottom: .25rem !important
	}

	.ml-1,
	.mx-1 {
		margin-left: .25rem !important
	}

	.m-2 {
		margin: .5rem !important
	}

	.mt-2,
	.my-2 {
		margin-top: .5rem !important
	}

	.mr-2,
	.mx-2 {
		margin-right: .5rem !important
	}

	.mb-2,
	.my-2 {
		margin-bottom: .5rem !important
	}

	.ml-2,
	.mx-2 {
		margin-left: .5rem !important
	}

	.m-3 {
		margin: 1rem !important
	}

	.mt-3,
	.my-3 {
		margin-top: 1rem !important
	}

	.mr-3,
	.mx-3 {
		margin-right: 1rem !important
	}

	.mb-3,
	.my-3 {
		margin-bottom: 1rem !important
	}

	.ml-3,
	.mx-3 {
		margin-left: 1rem !important
	}

	.m-4 {
		margin: 1.5rem !important
	}

	.mt-4,
	.my-4 {
		margin-top: 1.5rem !important
	}

	.mr-4,
	.mx-4 {
		margin-right: 1.5rem !important
	}

	.mb-4,
	.my-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-4,
	.mx-4 {
		margin-left: 1.5rem !important
	}

	.m-5 {
		margin: 3rem !important
	}

	.mt-5,
	.my-5 {
		margin-top: 3rem !important
	}

	.mr-5,
	.mx-5 {
		margin-right: 3rem !important
	}

	.mb-5,
	.my-5 {
		margin-bottom: 3rem !important
	}

	.ml-5,
	.mx-5 {
		margin-left: 3rem !important
	}

	.p-0 {
		padding: 0 !important
	}

	.pt-0,
	.py-0 {
		padding-top: 0 !important
	}

	.pr-0,
	.px-0 {
		padding-right: 0 !important
	}

	.pb-0,
	.py-0 {
		padding-bottom: 0 !important
	}

	.pl-0,
	.px-0 {
		padding-left: 0 !important
	}

	.p-1 {
		padding: .25rem !important
	}

	.pt-1,
	.py-1 {
		padding-top: .25rem !important
	}

	.pr-1,
	.px-1 {
		padding-right: .25rem !important
	}

	.pb-1,
	.py-1 {
		padding-bottom: .25rem !important
	}

	.pl-1,
	.px-1 {
		padding-left: .25rem !important
	}

	.p-2 {
		padding: .5rem !important
	}

	.pt-2,
	.py-2 {
		padding-top: .5rem !important
	}

	.pr-2,
	.px-2 {
		padding-right: .5rem !important
	}

	.pb-2,
	.py-2 {
		padding-bottom: .5rem !important
	}

	.pl-2,
	.px-2 {
		padding-left: .5rem !important
	}

	.p-3 {
		padding: 1rem !important
	}

	.pt-3,
	.py-3 {
		padding-top: 1rem !important
	}

	.pr-3,
	.px-3 {
		padding-right: 1rem !important
	}

	.pb-3,
	.py-3 {
		padding-bottom: 1rem !important
	}

	.pl-3,
	.px-3 {
		padding-left: 1rem !important
	}

	.p-4 {
		padding: 1.5rem !important
	}

	.pt-4,
	.py-4 {
		padding-top: 1.5rem !important
	}

	.pr-4,
	.px-4 {
		padding-right: 1.5rem !important
	}

	.pb-4,
	.py-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-4,
	.px-4 {
		padding-left: 1.5rem !important
	}

	.p-5 {
		padding: 3rem !important
	}

	.pt-5,
	.py-5 {
		padding-top: 3rem !important
	}

	.pr-5,
	.px-5 {
		padding-right: 3rem !important
	}

	.pb-5,
	.py-5 {
		padding-bottom: 3rem !important
	}

	.pl-5,
	.px-5 {
		padding-left: 3rem !important
	}


	.modal.show .modal-dialog {
		-webkit-transform: none;
		transform: none;
	}

	.modal.fade .modal-dialog {
		transition: -webkit-transform .3s ease-out;
		transition: transform .3s ease-out;
		transition: transform .3s ease-out,-webkit-transform .3s ease-out;
		-webkit-transform: translate(0,-50px);
		transform: translate(0,-50px);
	}

	@media (min-width: 576px){
		.modal-dialog-scrollable {
			max-height: calc(100% - 3.5rem);
		}
		.modal-dialog {
			max-width: 600px;
			margin: 1.75rem auto;
		}
	}

	.modal-dialog-scrollable {
		display: -ms-flexbox;
		display: flex;
		max-height: calc(100% - 1rem);
	}

	.modal-dialog {
		position: relative;
		width: auto;
		margin: 1.75rem auto;
		pointer-events: none;
	}


	.modal-content {
		position: relative;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		pointer-events: auto;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid rgba(0,0,0,.2);
		border-radius: .3rem;
		z-index: 111;
		outline: 0;
	}

	.modal-header {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: start;
		align-items: flex-start;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 1rem 1rem;
		border-bottom: 1px solid #dee2e6;
		border-top-left-radius: .3rem;
		border-top-right-radius: .3rem;
	}


	.modal-body {
		position: relative;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		padding: 1rem;
	}

	.modal-footer {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: end;
		justify-content: flex-end;
		padding: 1rem;
		border-top: 1px solid #dee2e6;
		border-bottom-right-radius: .3rem;
		border-bottom-left-radius: .3rem;
	}

	.modal-dialog-scrollable {
		display: flex; 
		max-height: calc(100% - 3.5rem);

		.modal-content {
			max-height: 600px;
			overflow: hidden;
		}

		.modal-header,
		.modal-footer {
			flex-shrink: 0;
		}

		.modal-body {
			overflow-y: auto;
		}
	}

	.modal-dialog-centered {
		display: flex;
		align-items: center;
		justify-content: center;
			min-height: calc(100% - 3.5rem);
	}


	.b-contain *, .b-contain *::before, .b-contain *::after {
		box-sizing: content-box !important;
	}

	.b-contain input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}

	.b-contain span {
		line-height: 1.54;
		font-size: 1rem;
		font-family: inherit;
	}

	.b-contain {
		display: table;
		position: relative;
		padding-left: 1.8rem;
		cursor: pointer;
	}

	.b-contain input[type="checkbox"] ~ .b-input {
		position: absolute;
		top: 0;
		left: 0;
		height: 1.25rem;
		width: 1.25rem;
		background: rgba(241, 245, 248, 1);
		transition: background 250ms;
		border: none;
		border: 1px solid rgba(184, 194, 204, 1);
		border-radius: 0.3rem;
	}

	.b-contain input[type="radio"] ~ .b-input {
		position: absolute;
		top: 0;
		left: 0;
		height: 1.25rem;
		width: 1.25rem;
		background: rgba(241, 245, 248, 1);
		transition: background 250ms;
		border: none;
		border-radius: 1.152rem;
	}

	.b-contain input[type="checkbox"] ~ .b-input::after {
		content: '';
		position: absolute;
		display: none;
		left: .45rem;
		top: .18rem;
		width: .25rem;
		height: .6rem;
		border: solid rgba(255, 255, 255, 1);
		border-width: 0 2px 2px 0;
		transition: background 250ms;
		transform: rotate(45deg);
	}

	.b-contain input[type="radio"] ~ .b-input::after {
		content: '';
		position: absolute;
		display: none;
		left: .25rem;
		top: .25rem;
		width: .75rem;
		height: .75rem;
		border-radius: 2.0rem;
		background: rgba(255, 255, 255, 1);
		transition: background 250ms;
	}

	.b-contain input:disabled ~ .b-input::after {
		border-color: rgba(135, 149, 161, 1);
	}

	.b-contain input:checked ~ .b-input::after {
		display: block;
	}

	.b-contain:hover input ~ .b-input,
	.b-contain input:focus ~ .b-input {
		background: rgb(231, 238, 243);
	}

	.b-contain input:focus ~ .b-input {
		box-shadow: 0 0 0 2px rgba(255, 173, 0, 0.5);
	}

	.b-contain input:checked ~ .b-input {
		background: rgba(255, 173, 0, 1);
		border-color: rgba(221, 146, 1, 1);
	}

	.b-contain input[type="checkbox"]:disabled ~ .b-input {
		background: rgba(241, 245, 248, 1);
		border-color: rgba(184, 194, 204, 1);
		opacity: 0.6;
		cursor: not-allowed;
	}

	.b-contain input[type="radio"]:disabled ~ .b-input {
		background: rgba(241, 245, 248, 1);
		border-color: rgba(184, 194, 204, 1);
		opacity: 0.6;
		cursor: not-allowed;
	}

	.b-contain input[type="radio"]:disabled ~ .b-input::after {
		background: rgba(135, 149, 161, 1);
	}

	.b-contain input:checked:focus ~ .b-input, .b-contain:hover input:not([disabled]):checked ~ .b-input {
		background: rgba(255, 173, 0, 1);
		border-color: rgba(255, 173, 0, 1);
	}

	.b-contain .b-input::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 3rem;
		height: 3rem;
		margin-left: -0.85rem;
		margin-top: -0.85rem;
		background: rgba(193, 0, 0, 1);
		border-radius: 2rem;
		opacity: .6;
		z-index: 99999;
		transform: scale(0);
	}

	@keyframes b-ripple {
		0% {
			transform: scale(0);
		}

		20% {
			transform: scale(1);
		}

		100% {
			opacity: 0;
			transform: scale(1);
			}
	}

	@keyframes b-ripple-duplicate {
		0% {
			transform: scale(0);
		}

		30% {
			transform: scale(1);
		}

		60% {
			transform: scale(1);
		}

		100% {
			opacity: 0;
			transform: scale(1);
			}
	}

	.b-contain input + .b-input::before {
		animation: b-ripple 250ms ease-out;
	}

	.b-contain input:checked + .b-input::before {
		animation-name: b-ripple-duplicate;
	}

	.b-contain .b-input::before {
		visibility: hidden;
	}

	.b-contain input:focus + .b-input::before {
		visibility: visible;
	}

	.b-contain:first-child .b-input::before {
		visibility: hidden;
	}

`

export default GlobalStyle