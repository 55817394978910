import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router";

function randomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const WauthContext = React.createContext({
  stateUser: {},
  loginWithRedirect: null,
  logout: null,
  isAuthenticated: null,
  isLoading: false,
  handleAuthentication: null,
})

export const useWauth = () => {
  return useContext(WauthContext)
};


const WauthProvider = ({domain, clientId, redirectUri, children}) => {
  const initialState = {
    user: {}
  };

  let location = useLocation()
  const [stateUser, setStateUser] = useState(initialState)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleAuthentication = () => {
    const params = getUrlParams()
    const idToken = params.get("#id_token")

    localStorage.setItem("auth", idToken);
    setIsAuthenticated(true)
  }

  function getUrlParams() {
    let arr = new Map();
    location.hash.split('&').map((par, index) => {
      arr.set(par.split('=')[0], par.split('=')[1]);
    })
    return arr
  }

  useEffect(() => {
    if (localStorage.auth) {
      const token = localStorage.auth ? localStorage.auth : "";
      setIsAuthenticated(true)
      setIsLoading(false)
    } else {
      setIsAuthenticated(false)
      setIsLoading(false)
    }
  }, []);

  const loginWithRedirect = () => {
    const authorizeEndpoint = domain + '/authorize'
    const state = randomString(20)

    const params = {
      state: state,
      redirect_uri: redirectUri,
      client_id: clientId,
      scope: "profile email"
    }

    const paramString = new URLSearchParams(params)
    window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
  }

  const logout = ({returnTo}) => {
    localStorage.removeItem('auth');
    const authorizeEndpoint = domain + '/auth/logout'

    const params = {redirect_uri: returnTo, client_id: clientId}
    const paramString = new URLSearchParams(params)
    window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
  }

  return (
    <WauthContext.Provider
      value={{
        stateUser,
        loginWithRedirect: loginWithRedirect,
        logout: logout,
        isAuthenticated: isAuthenticated,
        isLoading: isLoading,
        handleAuthentication: handleAuthentication,
      }}>
      {
        isLoading ? <div>Loading...</div> : children
      }
    </WauthContext.Provider>
  );

};

export default WauthProvider;
