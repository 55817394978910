import React, { useState } from 'react'
import styled from 'styled-components'
import destacado from '../Components/Images/icons/diamond.svg'
import top from '../Components/Images/icons/top-three.svg'
import nuevojuego from '../Components/Images/icons/new-product.svg'
import todos from '../Components/Images/icons/target.svg'
import jackpot from '../Components/Images/icons/slot-jackpot.svg'
import clasico from '../Components/Images/icons/cards.svg'
import frutas from '../Components/Images/icons/cherry.svg'
import egipcio from '../Components/Images/icons/sphinx.svg'
import asiatico from '../Components/Images/icons/templo-chino.svg'

import GamesBlock from "./GamesBlock"
import AllGamesBlock from "./AllGamesBlock"

import { useTranslation } from "react-i18next"
import ScrollTop from '../Components/ScrollTop'
import {useWauth} from "../auth/WauthProvider"

export const Container = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media screen and (min-width: 1200px){
        max-width: 1400px;   
    }
`
export const WrapperFilter = styled.div`
  background-color: #f2f0f0;
  padding: 1.2rem 0;
  width: 100%;
  transition: ease all .5s;
  z-index: 98;

  ${({sticky}) => sticky && `
  position: sticky;
  top: 75px;
  transition: ease all .5s;
  @media screen and (max-width: 1100px) {
    top: 63px;
  }
  @media screen and (max-width: 960px) {
    top: 53px;
  }
  @media screen and (max-width: 425px) {
    top: 43px; 
  }`    
  }
`
export const ContentFilter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border: 1px;
  font-size: 0.8rem;
  background-color: #FFAD00;
  border-radius: .45rem;
  -webkit-box-shadow: 0 4px 10px 2px rgb(0 0 0 / 25%);
  box-shadow: 0 4px 10px 2px rgb(0 0 0 / 25%);
`
export const ListFilter = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  text-align: center;
  whitespace: nowrap;
  overflow-x: auto;
`
export const Itemfilter = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.7rem;
  text-align: center;
  color: #0B1B28;
  white-space: nowrap;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  :hover {
    border-bottom: 2px solid #C10000;
  }
`
export const Input = styled.input`
  display: block;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0B1B28;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  margin: 0 1rem;
  max-width: 20%;
  min-width: 120px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-family: 'Lilita One', cursive;

  textarea.input {
    height: auto;
  }
`
export const ItemImg = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 0.2rem;
`
export const TitleCategory = styled.div`
  font-size: 1.2rem;
  border-bottom: 2px solid #ffad00;
  color: #0b1b28;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  justify-content: space-between;

  a {
    cursor: pointer;
    color: #C10000;
    text-decoration: none;
    margin-left: 0.5rem;
  }
`
export const ContentGames = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-gap: 10px;
  width: calc(100% - 40px);
  grid-auto-rows: 170px;
  transition: ease all .5s;
  padding: 2rem 0 1rem 0;
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(4, 25%);
    width: calc(100% - 30px);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 50%);
    width: calc(100% - 10px);
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 100%;
    grid-row-gap: 10px;
    grid-column-gap: 0px;
    width: 100%;
  }
`
export const GameItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: relative;
  border-radius: .5rem;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: ease all .5s;

  :hover {
    z-index: 97;
    transform-origin: center;
    transform: scale(1.15);
  }
`
export const GameItemOverlay = styled.div`
  background-color: transparent;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.2s ease-in-out;

  :hover {
    p {
      opacity: 0;
    }

    div {
      opacity: 1;
    }
  }
`
export const GameItemName = styled.p`
  width: 100%;
  background-color: #ffffff80;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
`
export const GameItemImg = styled.img`
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
`
export const GameActions = styled.div`
  opacity: 0;
  background-color: rgb(0 0 0 / 60%);
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 5px #2b2b2b;
  a {
    text-decoration: none;
    color: inherit;
  }
`
export const GameActionsPlay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 0 .7rem;
  border: 2px solid #C10000;
  border-radius: 4px;
  cursor: pointer;
  background: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  img {
    width: 30px;
    height: 30px;
  }

  h4 {
    margin: 0.8rem;
    color: #c10000;
  }
`
export const GameBigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: relative;
  border-radius: .5rem;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: ease all .5s;
  grid-row: span 2;
  grid-column: span 2;
  @media screen and (max-width: 425px) {
    grid-row: span 1;
    grid-column: span 1;
  }

  :hover {
    z-index: 97;
    transform-origin: center;
    transform: scale(1.15);
  }
`
export const GameBig = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: relative;
  border-radius: .5rem;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  grid-column: span 2;
  transition: ease all .5s;
  @media screen and (max-width: 425px) {
    grid-row: span 1;
    grid-column: span 1;
  }

  :hover {
    z-index: 97;
    transform-origin: center;
    transform: scale(1.15);
  }
`

export const LoadMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #ffad00;
`

const Tragamonedas = () => {

  const [filtro, setFiltro] = useState(false);
  const [t]=useTranslation("global")

  const stickyFiltro = () => {
    if (window.scrollY >= 90) {
      setFiltro(true)
    } else {
      setFiltro(false)
    }
  }

  window.addEventListener('scroll', stickyFiltro)

  const [showCategory, setShowCategory] = useState(null)

  const categoryClicked = (category) =>{
    setShowCategory(category)
  }

  const [searchTerm, setSearchTerm] = useState("");

  const {isAuthenticated} = useWauth()
  const isLoggedIn = isAuthenticated


  return (
    <>
    <ScrollTop/>

      <WrapperFilter sticky={filtro}>
        <ContentFilter>
          <ListFilter>
            <Itemfilter onClick={() => categoryClicked('destacados')}>
              <ItemImg src={destacado} alt="destacados"/>
              {t("filtros.destacados")}
            </Itemfilter>
            <Itemfilter onClick={() =>  categoryClicked('popular')}>
              <ItemImg src={top} alt="masJugados"/>
              {t("filtros.mas-jugados")}
            </Itemfilter>

            <Itemfilter onClick={() => categoryClicked('nuevos_juegos')}>
              <ItemImg src={nuevojuego} alt="nuevoJuego"/>
              {t("filtros.nuevos-juegos")}
            </Itemfilter>
            <Itemfilter onClick={() => categoryClicked('')}>
              <ItemImg src={todos} alt="todos"/>
              {t("filtros.todos")}
            </Itemfilter>
            <Itemfilter onClick={() => categoryClicked('jackpots')}>
              <ItemImg src={jackpot} alt="jackpot"/>
              {t("filtros.jackpots")}
            </Itemfilter>
            <Itemfilter onClick={() => categoryClicked('clasicos')}>
              <ItemImg src={clasico} alt="clasico"/>
              {t("filtros.clasicos")}
            </Itemfilter>
            <Itemfilter onClick={() => categoryClicked('frutas')}>
              <ItemImg src={frutas} alt="frutas"/>
              {t("filtros.frutas")}
            </Itemfilter>
            <Itemfilter onClick={() => categoryClicked('egipcios')}>
              <ItemImg src={egipcio} alt="egipcio"/>
              {t("filtros.egipcios")}
            </Itemfilter>
            <Itemfilter onClick={() => categoryClicked('asiaticos')}>
              <ItemImg src={asiatico} alt="asiatico"/>
              {t("filtros.asiaticos")}
            </Itemfilter>
          </ListFilter>
          <Input type='text' placeholder={t("filtros.buscar")} onChange={(event)=>{setSearchTerm(event.target.value)}}/>

        </ContentFilter>
      </WrapperFilter>
      {
        (() => {
          switch (showCategory) {
            case 'favoritos':
              return (
                  <GamesBlock key="favoritos" title={t("filtros.favoritos")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'destacados':
              return (
                  <GamesBlock key="destacados" title={t("filtros.destacados")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'popular':
              return ( 
                  <GamesBlock key="popular" title={t("filtros.mas-jugados")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'nuevos_juegos':
              return ( 
                  <GamesBlock key="nuevos_juegos" title={t("filtros.nuevos-juegos")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/> 
                )
            case '':
              return ( 
                  <GamesBlock key="todos" title={t("filtros.todos")} tag={''} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'jackpots':
              return ( 
                  <GamesBlock key="jackpots" title={t("filtros.jackpots")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'clasicos':
              return ( 
                  <GamesBlock key="clasicos" title={t("filtros.clasicos")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'frutas':
              return ( 
                  <GamesBlock key="frutas" title={t("filtros.frutas")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'egipcios':
              return (
                  <GamesBlock key="egipcios" title={t("filtros.egipcios")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            case 'asiaticos':
              return ( 
                  <GamesBlock key="asiaticos" title={t("filtros.asiaticos")} tag={showCategory} changeCategory={categoryClicked} searchTerm={searchTerm}/>
                )
            default:
              return (
                <Container>
                  {isLoggedIn? 
                  <AllGamesBlock title={t("filtros.favoritos")} tag={'favoritos'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>
                  :<></>
                  }
                  <AllGamesBlock title={t("filtros.destacados")} tag={'destacados'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.mas-jugados")} tag={'popular'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.nuevos-juegos")} tag={'nuevos_juegos'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.todos")} tag={''} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.jackpots")} tag={'jackpots'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.clasicos")} tag={'clasicos'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.frutas")} tag={'frutas'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.egipcios")} tag={'egipcios'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>

                  <AllGamesBlock title={t("filtros.asiaticos")} tag={'asiaticos'} searchTerm={searchTerm} setShowCategory={setShowCategory}/>
                </Container>
              )
          }
        })()
      }

    </>
  )
}

export default Tragamonedas
