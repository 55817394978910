import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FooterContainer = styled.div`
  background-color: #101522;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FooterContainerWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:row;
  width:100%;
  padding:0 3rem;
  transition: all 0.5s ease;
  @media screen and (max-width: 960px) {
    flex-direction:column;
    padding:0 2rem;
  }
  @media screen and (max-width: 425px) {
    padding:0 1rem;
  }
`
export const FooterSectionLogo = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  margin: 0 4rem;
`


export const FooterLinksContainer = styled.div`
  display:flex;
  justify-content:cemter;
  flex-direction: row;
  @media screen and (max-width: 960px) {
    margin-top:2rem;
  }
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`
export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1.5rem;
  text-align: left;
  box-sizing: border-box;
  color: #fff;
  @media screen and (max-width: 960px) {
    align-items: center;
    margin: 2rem;
  }
  @media screen and (max-width: 425px) {
    margin: 0;
    padding: 1rem;
    width: 100%;
    
  }
`;

export const FooterLinkTitle = styled.h2`
  margin-bottom: 16px;
  font-weight: normal;
  text-transform: uppercase;
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  &:hover {
    color: #ffad00;
    transition: 0.2s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 3rem auto 0 auto;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin: 2rem auto 2rem auto;
  }
`;


export const WebsiteRights = styled.p`
  color: #fff;
  @media screen and (max-width: 960px) {
    margin-bottom: 2rem;
  }
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  transform: scale(1.1);
  &:hover {
    transform: scale(1.5);
    transition: 0.3s ease-out;
  }
`

export const LogoResponsable = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  @media screen and (max-width: 960px) {
    margin-bottom: 2rem;
  }
`