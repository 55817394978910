import React from 'react'

const OtrosJuegos = () => {
  return (
    <div>
      Otros juegos
    </div>
  )
}
export default OtrosJuegos
