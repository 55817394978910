import React, { useEffect, useState } from 'react'
import Button from '../Components/Button'
import {
  ContentGames,
  GameActions,
  GameItem,
  GameItemImg,
  GameItemName,
  GameItemOverlay,
  TitleCategory
} from "./Tragamonedas"
import playgame from '../Components/Images/icons/tocar.svg'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import axios from "axios"
import {useTranslation} from "react-i18next"
import {Apis} from "../util/Apis"
import {getNewGames,getFavoriteGames} from '../Services/games.service'
import useFetch from "../hooks/useFetch"
import ribbon_new from '../Components/Images/corner_new.png'

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: 1200px) {
    max-width: 1400px;
  }
`
export const ImgNew = styled.img`
  position: absolute;
  top: -9px;
  left: -9px;
  min-width: 85px;
  min-height: auto;
  width: 85px;
  height: auto;
  border-radius: 0;
`
const PlayFreeLink = styled(Link)`
  position: absolute;
  bottom: 10px;
  left: 0;
  display:flex;
  justify-content:center;
  width:100%;
  h4{
    text-transform:uppercase;
    font-weight: normal;
    font-size:1.2rem;
  }
`
export const ContentDivider = styled.div`
  display:flex;
  align-items:center;
  padding-bottom:1rem;
`
export const DividerStyled = styled.hr`
  flex:1;
  height:2px;
  border:none;
  border-top:1px solid rgb(255, 173, 0);
  border-radius:4px;
`

function AllGamesBlock({title,setShowCategory, tag, searchTerm = ""}) {

  const [gamesValues, setGamesValues] = useState([])
  const [juegosNuevos, setJuegosNuevos] = useState([])
  const [juegosFavoritos, setJuegosFavoritos] = useState([])

  const loadGames = () => {
    const params = {portalId: 77, tag: tag}
    switch (tag) {
      case 'popular':
        return (
          axios.get(Apis.BASE + '/games/popular', {params: params}).then(res => {
            setGamesValues(res.data)
          }).catch(err => {
            console.log('hubo un error')
          })
        )
      case 'destacados':
        return ( 
            axios.get(Apis.BASE + '/games/featured', {params: params}).then(res => {
              setGamesValues(res.data)
            }).catch(err => {
              console.log('hubo un error')
            })
          )
      case 'nuevos_juegos':
        return ( 
          axios.get(Apis.BASE + '/games/recent', {params: params}).then(res => {
            setGamesValues(res.data)
          }).catch(err => {
            console.log('hubo un error')
          })
          )
      case 'favoritos':
        return ( 
          axios.get(Apis.BASE + `/games/favorites`, {params: params, headers: {'Authorization': 'Bearer ' + localStorage.getItem('auth')}}).then(res => {
            setGamesValues(res.data)
          }).catch(err => {
            console.log('hubo un error')
          })
        )
      default:
        return (
          axios.get(Apis.BASE + '/games', {params: params}).then(res => {
            setGamesValues(res.data)
          }).catch(err => {
            console.log('hubo un error')
          })
        )
    }
 
  }

  useEffect(() => {
    loadGames() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  useEffect(() => {
    async function cargarJuegosNuevos(){
      const response =await getNewGames()
      if(response.status === 200){
        setJuegosNuevos(response.data)
      }
      return response
    }
    cargarJuegosNuevos()
  },[])

  const juegosNuevosId = juegosNuevos.map(function(newgame) {
    return newgame.id
  })

  function exists(value, array) {
    return array.some(e => e === value);
  }

  useEffect(() => {
    if (localStorage.getItem('auth') !== undefined && localStorage.getItem('auth') !== null) {
      async function cargarJuegosFavoritos(){
        await getFavoriteGames().then(res => {
          setJuegosFavoritos(res.data)
        }).catch(err => {
          console.log('hubo un error')
        })
      }
      cargarJuegosFavoritos()
    }
  },[])

  const juegosFavoritosId = juegosFavoritos.map(function(favoritegame) {
    return favoritegame.id
  })

  const [t] = useTranslation("global")

  const renderGameItems = gamesValues.filter((games) => {
    if (searchTerm === "") {
      return games
    } else if (games.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      return games
    }
  })
    .slice(0, 10).map((gamesValues, i) => {
      return (
        <GameItem key={i}>
          <GameItemImg src={gamesValues.imgUrl} rel="preload" alt="GameImg"/>
           { exists(gamesValues.id, juegosNuevosId) ?<ImgNew src={ribbon_new} alt="GameImg" />:<></>}
          <GameItemOverlay>
            {/* <GameItemName>{gamesValues.name}</GameItemName> */}
            <GameActions>
            { exists(gamesValues.id, juegosFavoritosId) ? 
              <>
                <Link to={`/games-loader/${gamesValues.id}/true?mode=real`}>
                  <img src={playgame} alt="money" width="60px" height="60px"/>
                </Link>
                <PlayFreeLink to={`/games-loader/${gamesValues.id}/true?mode=fun`}>
                  <h4>{t("block-game.demo")}</h4>
                </PlayFreeLink>
              </>
              :
              <>
                <Link to={`/games-loader/${gamesValues.id}/false?mode=real`}>
                  <img src={playgame} alt="money" width="60px" height="60px"/>
                </Link>
                <PlayFreeLink to={`/games-loader/${gamesValues.id}/false?mode=fun`}>
                  <h4>{t("block-game.demo")}</h4>
                </PlayFreeLink>
              </>
            }
            </GameActions>
          </GameItemOverlay>
        </GameItem>
      )
    }); 
  
  return (
    <>
    { gamesValues.length > 0 ?
      <div>
        <TitleCategory>
          <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
            <p>{title}</p> <p style={{padding:"0 0.5rem", color:"#c10000"}}>({gamesValues.length})</p>
          </div>
        </TitleCategory>
        <ContentGames>
        {renderGameItems}
      </ContentGames>
        <ContentDivider>
          <DividerStyled/>
            <Button  style={{flex:"1"}} content={t("block-game.ver-mas")} btnstyle='btn-outline-danger' clicked={() => setShowCategory(tag)}/>
          <DividerStyled/>
        </ContentDivider>
      </div>
      :
      <></>
    }
  </>    
  )
}

export default AllGamesBlock
