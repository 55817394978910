import styled from "styled-components"

export const Field = styled.div`
    margin-bottom: 1rem;
`

export const Control= styled.div`
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: left;
`

export const Label = styled.label`
    display: inline-block;
    margin-bottom: .5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight:600;
`

export const Input = styled.input`
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: 'Open Sans', sans-serif;

    textarea.input {
        height: auto;
    }
`
export const TextMuted = styled.small`
    color: #6c757d !important;
    display: block;
    margin-top: .25rem;
    font-family: 'Open Sans', sans-serif;
`

export const Form = styled.form`
    width: 100%;
`

export default Form