import React, {useEffect,useState} from 'react'
import styled from 'styled-components'
import Button from '../Components/Button'
import axios from "axios"
import {Apis} from "../util/Apis"
import {useTranslation} from "react-i18next"
import {ModalHeader, ModalTitle, ModalFooter} from "../Components/Modal"
import * as FaIcons from 'react-icons/fa'
import { bankData } from '../Components/bankList.js'

import banktransfer from '../Components/Images/payments/bank-transfer.svg'

export const TransferenciaTitulo = styled.div`
  width: 100%;
  padding: 0 2rem 2rem;
  text-align: center;
  img{
    height:70px;
  }
  @media screen and (max-width: 580px) {
    padding: 0 0.5rem 0.5rem;
    img{
      height:60px;
    }
  }
`

export const MetodoBoton = styled.div`
  display:flex;
  width:100%;
  justify-content:space-around;
  align-items:center;
  
  @media screen and (max-width: 580px) {
    }
`
export const ListaPasos = styled.ul`
  list-style-type:decimal;
  list-style-position:outside;
  font-size:0.8rem;
`

export const Li = styled.li`
  font-family:"Open Sans";
  padding-bottom:0.5rem;
  p{
    font-family:"Open Sans";
  }
  h4{
    font-family:"Open Sans";
    padding:0.5rem 0;
  }
`
export const ContenidoTransferencia = styled.div`
  width:100%;
  padding:0 2rem 1rem;
  @media screen and (max-width: 580px) {
    padding: 0 0.5rem 1rem;
  }
`
export const Titulo = styled.h4`
  font-family:"Open Sans";
  font-size:1.1rem;
  padding:0.5rem 0 1rem 0;
  text-align:center;
`

const PagoTransferencia = ({cambiarMetodo,modalRecarga}) => {

  const [t] = useTranslation("global")
  const [dataValues, setDataValues] = useState([])
  const loadData = () => {
    axios.get(Apis.BASE + '/me', { responseType: "json",
      headers: { 'Authorization': 'Bearer '+localStorage.getItem('auth') }
    }).then(res => {
      setDataValues(res.data)
    }).catch(err => {
      console.log('hubo un error')
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  


  return (
    <> 
      <ModalHeader className="modal-header">
        <ModalTitle>{t("transferencia.titulo")}</ModalTitle>
        <FaIcons.FaTimes onClick={() => modalRecarga.current.close()}/>
      </ModalHeader>
      <div className="modal-body">
        <TransferenciaTitulo>
          <img src={banktransfer} alt=""></img>
        </TransferenciaTitulo>
        <ContenidoTransferencia>
          <Titulo>{t("transferencia.subtitulo")}</Titulo>
          <ListaPasos>
            <Li>{t("transferencia.text-1")}
              
            <div>
              {bankData.map((data, key) => {
                return (
                  <div key={key}>
                    <h4>{t("transferencia.banco")} {data.banco}</h4>
                    <p>{t("transferencia.cliente")} {data.cliente}</p>
                    <p>{t("transferencia.cuenta")} {data.currency}: {data.accNumber}</p>
                  </div>
                );
              })}
            </div>
            </Li>
            <Li>{t("transferencia.text-2")} <label>{dataValues.currency}</label> )</Li>
            <Li>{t("transferencia.text-3")} <label>{dataValues.currencySymbol} 20.00</label></Li>
            <Li>{t("transferencia.text-4")}</Li>
            <Li>{t("transferencia.text-5")}</Li>
          </ListaPasos>
        </ContenidoTransferencia>
      </div>
      <ModalFooter>
        <MetodoBoton>
          <Button content={t("pagovisa.cambiar")} btnstyle='btn-danger' clicked={()=>cambiarMetodo('default')}/>
          <Button content={t("transferencia.aceptar")} btnstyle='btn-secondary' clicked={() => modalRecarga.current.close()}/>
        </MetodoBoton>
      </ModalFooter>
    </>
  )
}

export default PagoTransferencia
