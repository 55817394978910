import React, {useContext, useState} from 'react'
import Button from '../Components/Button'
import Form, { Field,Control,Label,Input } from '../Components/Form'
import AuthContext from "./store/AuthContext"
import {Redirect} from "react-router-dom"
import {loginUser} from "./actions/authentication.action"
import casinodata from '../Components/saladata.json'

import { useTranslation } from "react-i18next"

const LoginPage = () => {

  const {stateUser, dispatch} = useContext(AuthContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const [signIn, setSignIn] = useState(true);

  const [t]=useTranslation("global")

  function doLogin() {
    if (username === "" || password === "") {
      setError('Ingrese sus datos correctamente');
    } else {
      loginUser(username, password,60, dispatch, setError, setLoading);
    }
  }

  function signin() {
    setSignIn(false)
  }

  const isLoggedIn = stateUser.isAuthenticated
  const referer = '/'
  //https://medium.com/better-programming/building-basic-react-authentication-e20a574d5e71
  if (isLoggedIn) {
    return <Redirect to={referer}/>;
  }

  return (
    <>
    {signIn ? 
    <>
      <Form style={{padding:'2rem'}}>
        <div  style={{color:'#0B1B28', textAlign:'center', fontSize:'1.5rem', cursor:'pointer', paddingBottom:'1rem'}}>
        <img src={casinodata.logo} alt="" width="auto" height="120"/>
        </div>
        <Field>
          <Control>
            <Label >{t("popup-login.documento")}</Label>
            <Input type="text" name='document' value={username} onChange={(e) => setUsername(e.target.value)} autoFocus required autoComplete="off"/>
          </Control>
        </Field>

        <Field>
          <Control>
            <Label >{t("popup-login.contrasena")}</Label>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name='password' required/>
          </Control>
        </Field>

        <p style={{textAlign: "center", color:'#C10000', cursor:'pointer',margin:"0"}}>{error}</p>

      </Form>
      <div style={{display:'flex',justifyContent:'space-around',alignItems:'space-around', paddingTop:'2rem'}}>
        <Button content={loading ? <>{t("popup-login.loading")}</> : <>{t("popup-login.ingresar")}</>} btnstyle='btn btn-secondary' clicked={doLogin}/>
        <Button content={t("popup-login.crear-cuenta")} btnstyle='btn btn-danger' clicked={signin}/>
      </div>
    </>
    :
    <>
    <Form style={{padding:'2rem'}}>
        <div  style={{color:'#0B1B28', textAlign:'center', fontSize:'1.5rem', cursor:'pointer', paddingBottom:'1rem'}}>
        <img src={casinodata.logo} alt="" width="auto" height="120"/>
        </div>
        <Field>
          <Control>
            <Label >{t("popup-login.documento")}</Label>
            <Input type="text" name='document' value={username} onChange={(e) => setUsername(e.target.value)} autoFocus required autoComplete="off"/>
          </Control>
        </Field>

        <Field>
          <Control>
            <Label >{t("popup-login.contrasena")}</Label>
            <Input type="password" onChange={(e) => setPassword(e.target.value)} name='password' required/>
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>{t("popup-login.contrasena2")}</Label>
            <Input type="password" onChange={(e) => setConfirmPassword(e.target.value)} name='password' required/>
          </Control>
        </Field>

        <p style={{textAlign: "center", color:'#C10000', cursor:'pointer',margin:"0"}}>{error}</p>

      </Form>
      <div style={{display:'flex',justifyContent:'space-around',alignItems:'space-around', paddingTop:'2rem'}}>
        <Button content={loading ? <>{t("popup-login.loading")}</> : <>{t("popup-login.crear-cuenta")}</>} btnstyle='btn btn-secondary' clicked={doLogin}/>
        <Button content={t("popup-login.regresar")} btnstyle='btn btn-danger' clicked={()=>setSignIn(true)}/>
        
      </div>
    </>
    }
    </>
  )
}

export default LoginPage
