import React, {forwardRef, useImperativeHandle} from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"

const ModalBackdrop = styled.div`
  	position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 110;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(2px);
`
const ModalWrapper = styled.div`
  	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 0;
    z-index: 110;	
    overflow-y: auto;
`

export const ModalContent = styled.div`
  	position: relative;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
	  z-index: 111;
    &.modal-content {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.2);
      border-radius: .3rem;
      z-index: 111;
      outline: 0;
  }
    font-family: 'Open Sans', sans-serif;
    @media screen and (max-width: 580px) {
      width: 95%;
    }
`

export const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    background: #FFAD00;
    color:#0B1B28;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    &.modal-header {
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 1rem 1rem;
      border-bottom: 1px solid #dee2e6;
      border-top-left-radius: .3rem;
      border-top-right-radius: .3rem;
  }
`

export const ModalTitle = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.3rem;
    font-family: 'Lilita One', cursive;
`

export const ModalBody  = styled.div`
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    flex-direction: column;
`

export const ModalFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 0.8rem 1rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
    font-size: 14px;
    background-color: #f5f5f5;
    &.modal-footer {
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      padding: 1rem;
      border-top: 1px solid #dee2e6;
      border-bottom-right-radius: .3rem;
      border-bottom-left-radius: .3rem;
    }
    @media screen and (max-width: 960px) {
      justify-content: center;
    }
`

export const ModalCheckIcon = styled.div`
    font-size:2rem;
    color:#00c851;
`


const Modal = forwardRef((props,ref) => {
  
  const [display, setDisplay] = React.useState(false);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => open(),
      close: () => close()
    }
  });

  const open = () => {
    setDisplay(true)
  };

  const close = () => {
    setDisplay(false);
  };

  if (display) {
    return ReactDOM.createPortal(
      <ModalWrapper>
        <ModalBackdrop onClick={close} />
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                {props.children}
        </div>
        
      </ModalWrapper>, document.getElementById("modal-root"))
  }

  return null;

});

export default Modal