import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import Button from '../Components/Button'
import axios from "axios"
import {Apis} from "../util/Apis"

import logovisamastercard from '../Components/Images/payments/logovisa.png'
import banktransfer from '../Components/Images/payments/bank-transfer.svg'
import logoyape from '../Components/Images/payments/logoyape.png'
import * as FaIcons from 'react-icons/fa'
import PagoVisa from './PagoVisa'
import PagoTransferencia from './PagoTransferencia'
import {useTranslation} from "react-i18next"
import PagoQr from './PagoQr'
import {ModalHeader, ModalTitle, ModalFooter, ModalContent} from "../Components/Modal"

export const Contenido = styled.div`
  width:100%;
  padding:1rem;
  @media screen and (max-width: 580px) {
    padding:0.5rem;
    }
`

export const MetodoTitulo = styled.p`
  font-family:Open Sans;
  text-align:center;
  font-size:1.1rem;
`
export const MetodoSubtitulo = styled.p`
  font-family:Open Sans;
  text-align:center;
  margin:1rem;
`
export const RecargaSelect = styled.a`
  display:flex;
  width:100%;
  border-radius:5px;
  text-decoration:none;
  color:inherit;
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 5px 0px, rgb(0 0 0 / 12%) 0px 2px 10px 0px;
  margin:0 0 1rem 0;
`
export const SelectIzq = styled.div`
  display:flex;
  width:45%;
  padding:0.5rem 1rem;
  justify-content:center;
  align-items:center;
  img{
    height:70px;
  }
  @media screen and (max-width: 580px) {
    width:40%;
    padding:0;
    img{
      padding:0.5rem;
    }
  }
`
export const SelectDer = styled.div`
  display:flex;
  width:45%;
  padding:0.5rem 1rem;
  justify-content:center;
  align-items:center;
  font-size:1.1rem;
  @media screen and (max-width: 580px) {
    width:50%;
  }
`
export const SelectFlecha = styled.div`
  display:flex;
  width:10%;
  padding:0 1rem;
  justify-content:center;
  align-items: center;
  font-size:1.3rem;
  @media screen and (max-width: 580px) {
    padding:0 0.5rem;
  }
`

const MetodoPago = ({modalRecarga}) => {

  const [metodopago, setMetodoPago] = useState(null)
  const [t] = useTranslation("global")

  const [dataValues, setDataValues] = useState([])
  const loadData = () => {
    axios.get(Apis.BASE + '/me', { responseType: "json",
      headers: { 'Authorization': 'Bearer '+localStorage.getItem('auth') }
    }).then(res => {
      setDataValues(res.data)
    }).catch(err => {
      console.log('hubo un error')
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
      <ModalContent width={'100%'} className="modal-content">
        {
        (() => {
          switch (metodopago) {
            case 'visa':
              return (
                  <PagoVisa modalRecarga={modalRecarga} cambiarMetodo={setMetodoPago}/>
                )
            case 'transferencia':
              return ( 
                  <PagoTransferencia modalRecarga={modalRecarga} cambiarMetodo={setMetodoPago}/>
                )
            case 'pagoqr':
              return (
                  <PagoQr modalRecarga={modalRecarga} cambiarMetodo={setMetodoPago}/>
                )
            default:
              return (
                <>
                <ModalHeader className="modal-header">
                  <ModalTitle>{t("popup-recarga.titulo")}</ModalTitle>
                  <FaIcons.FaTimes onClick={() => modalRecarga.current.close()}/>
                </ModalHeader>
                <div className="modal-body">
                  <Contenido>
                    <MetodoTitulo>{t("popup-recarga.subtitulo")}</MetodoTitulo>

                    <MetodoSubtitulo>{t("popup-recarga.elegir-metodo")}</MetodoSubtitulo>

                    <RecargaSelect href='#' onClick={()=>setMetodoPago('visa')}>
                      <SelectIzq>
                        <img src={logovisamastercard} alt=""></img>
                      </SelectIzq>
                      <SelectDer>
                        <p style={{fontFamily:"Open Sans", textAlign:"center"}}>{t("popup-recarga.visa")}</p>
                      </SelectDer>
                      <SelectFlecha>
                        <FaIcons.FaChevronRight/>
                      </SelectFlecha>
                    </RecargaSelect>
                    <RecargaSelect href='#' onClick={()=>setMetodoPago('transferencia')}>
                      <SelectIzq>
                        <img src={banktransfer} alt=""></img>
                      </SelectIzq>
                      <SelectDer>
                      <p style={{fontFamily:"Open Sans", textAlign:"center"}}>{t("popup-recarga.transferencia")}</p>
                      </SelectDer>
                      <SelectFlecha>
                        <FaIcons.FaChevronRight/>
                      </SelectFlecha>
                    </RecargaSelect>
                    <RecargaSelect href='#' onClick={()=>setMetodoPago('pagoqr')}>
                      <SelectIzq>
                        <img src={logoyape} alt=""></img>
                      </SelectIzq>
                      <SelectDer>
                      <p style={{fontFamily:"Open Sans", textAlign:"center"}}>{t("popup-recarga.pagoqr")}</p>
                      </SelectDer>
                      <SelectFlecha>
                        <FaIcons.FaChevronRight/>
                      </SelectFlecha>
                    </RecargaSelect>
                  </Contenido>
                </div>
                <ModalFooter>
                  <p className='text-muted'>{t("popup-recarga.monto-minimo")} {dataValues.currencySymbol} 20 {dataValues.currency}</p>
                  <Button content={t("popup-recarga.cancelar")} btnstyle='btn-small btn-outline-danger' icon='fas fa-times' clicked={() => modalRecarga.current.close()}/>
                </ModalFooter>
                </>
              )
          }
        })()
      }
      </ModalContent>

      
  )
}

export default MetodoPago
