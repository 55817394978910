import React, { useState, useEffect } from 'react'
import * as FaIcons from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import Button from '../Button'
import slotMachine from '../Images/icons/slot-machine.svg'
import dealer from '../Images/icons/dealer.svg'
import pokerfull from '../Images/icons/poker-full.svg'
import bingo from '../Images/icons/bingo.svg'
import salir from '../Images/icons/logout.svg'
import perfil from '../Images/icons/user.svg'
import casinodata from '../saladata.json'

import Modal, {ModalHeader, ModalTitle, ModalBody, ModalContent} from "../Modal"
import {
  Nav,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemImg,
  NavMobileBtn,
  NavRightMenu,
  NavRightSaldo,
  NavLinks,
  NavMenuContent,
  NavSalir,
  NavMobilePerfil,
  ImgPerfil,
  SaldoUsuario,
  MenuMobile,
  NavLng
} from './Navbar.elements';
import LoginPage from '../../login/LoginPage'
import { useTranslation } from "react-i18next"
import {useWauth} from "../../auth/WauthProvider"
import axios from "axios"
import {Apis} from "../../util/Apis"
import MetodoPago from '../../pages/MetodoPago'

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  console.log(dropdown)

  const {isAuthenticated, loginWithRedirect, logout} = useWauth()
  const isLoggedIn = isAuthenticated

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth <= 1100) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth <= 1100) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const modalLogin = React.useRef();
  const modalRecarga = React.useRef();
  //const openModalLogin = () => {modalLogin.current.openModal()};
  const openModalLogin = loginWithRedirect
  const openModalRecarga = () => {modalRecarga.current.openModal()};

  const [t,i18n]=useTranslation("global")
  const [lenguaje, setLenguaje] = useState("")

  const goToBackoffice = () => {
    window.location.href = 'https://my.casinokololi8888.com'
  }

  const goLogout = () => {
    logout({
      returnTo: window.location.origin,
    })
  }


  const [dataValues, setDataValues] = useState([])



  useEffect(() => {
    const loadData = () => {
      axios.get(Apis.BASE + '/me', { responseType: "json",
        headers: { 'Authorization': 'Bearer '+localStorage.getItem('auth') }
      }).then(res => {
        setDataValues(res.data)
      }).catch(err => {
        console.log('hubo un error')
      })
    }
  }, [])

  function setIdioma(idm) {
    localStorage.setItem('i18nextLng', idm)
    document.cookie = "i18next=" + idm + ""
  }
  useEffect(() => {
    let idioma = localStorage.getItem('i18nextLng')
    if (idioma !== undefined && idioma !== null) {
      i18n.changeLanguage(idioma)
      setLenguaje(idioma)
    }
  }, [i18n])

  return (
    <>
      <IconContext.Provider value={{ color: '#0B1B28' }}>
        <Nav>
          <NavLogo to='/' onClick={closeMobileMenu} aria-label="logo">
            <img src={casinodata.logo} alt="" aria-label="logo"></img>
          </NavLogo>

          <NavMenuContent>
            {isLoggedIn ? 
              <MenuMobile>
                <Button content={t("header.recargar")} btnstyle='btn-danger' clicked={openModalRecarga}/>
                <MobileIcon onClick={handleClick}>
                  {click ? <FaIcons.FaTimes /> : <FaIcons.FaBars />}
                </MobileIcon>
              </MenuMobile>
              :<MenuMobile>
                <Button content={t("header.login")} btnstyle='btn-secondary' clicked={loginWithRedirect}/>
                <MobileIcon onClick={handleClick}>
                  {click ? <FaIcons.FaTimes /> : <FaIcons.FaBars />}
                </MobileIcon>
              </MenuMobile>
            }
            
            <NavMenu click={click}>
              {isLoggedIn ? 
                <NavMobilePerfil>
                  <ImgPerfil src={perfil} alt="" />

                  <NavLinks to='/' onClick={closeMobileMenu}>
                    <Button content={t("header.mi-cuenta")} btnstyle='btn-secondary' clicked={goToBackoffice}/>
                  </NavLinks>

                  <SaldoUsuario>
                    <div>{t("header.saldo")}: {dataValues.currencySymbol} {dataValues.balance}</div>
                    <div>{t("header.bono")}: {dataValues.currencySymbol} 0.00</div>
                  </SaldoUsuario>
                  <NavLng>
                    <p>{t("header.lenguaje")}</p>
                    <select onChange={(e)=>{
                      const selectedLng=e.target.value;
                      setLenguaje(selectedLng)
                      i18n.changeLanguage(selectedLng)
                      setIdioma(selectedLng)
                    }}
                    value={lenguaje}>
                      <option value="es">ESPAÑOL</option>
                      <option value="en">ENGLISH</option>
                      <option value="ch">中國人</option>
                      <option value="fr">FRANÇAIS</option>
                    </select>
                  </NavLng>
                </NavMobilePerfil>
                :<>
                </>
              }
              {isLoggedIn ? 
                <>
                </>
                :<>
                  <NavMobileBtn>
                    <NavLng>
                      <p>{t("header.lenguaje")}</p>
                      <select onChange={(e)=>{
                        const selectedLng=e.target.value;
                        setLenguaje(selectedLng)
                        i18n.changeLanguage(selectedLng)
                        setIdioma(selectedLng)
                      }}
                      value={lenguaje}>
                        <option value="es">ESPAÑOL</option>
                        <option value="en">ENGLISH</option>
                        <option value="ch">中國人</option>
                        <option value="fr">FRANÇAIS</option>
                      </select>
                    </NavLng>
                  </NavMobileBtn>
                </>
              }
              <NavItem>
                <NavLinks to='/' onClick={closeMobileMenu}>
                  <NavItemImg src={slotMachine} alt="tragamonedas" />
                  <p>{t("header.tragamonedas")}</p>
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/casino-en-vivo' onClick={closeMobileMenu}>
                  <NavItemImg src={dealer} alt="en vivo" />
                  <p>{t("header.casino-en-vivo")}</p>
                </NavLinks>
              </NavItem>
              <NavItem onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <NavLinks to='/mesas' onClick={closeMobileMenu}>
                  <NavItemImg src={pokerfull} alt="mesas" />
                   <p>{t("header.juegos-de-mesa")} {/*<FaIcons.FaCaretDown /> */}</p> 
                </NavLinks>
                {/* {dropdown && <DropdownMesa />} */}
              </NavItem>
              <NavItem>
                <NavLinks to='/otros-juegos' onClick={closeMobileMenu}>
                  <NavItemImg src={bingo} alt="otros" />
                  <p>{t("header.otros")}</p>
                </NavLinks>
              </NavItem>

              {isLoggedIn ? 
                <NavMobileBtn>
                  <NavLinks to='/' onClick={closeMobileMenu}>
                    <Button content={t("header.cerrar-sesion")} btnstyle='btn-danger' clicked={goLogout}/>
                  </NavLinks>
                </NavMobileBtn>
                :<>
                </>
              }
              
            </NavMenu>

            <NavRightMenu>
              {isLoggedIn ? 
                <>
                  <NavLng>
                    <p>{t("header.lenguaje")}</p>
                    <select onChange={(e)=>{
                      const selectedLng=e.target.value;
                      setLenguaje(selectedLng)
                      i18n.changeLanguage(selectedLng)
                      setIdioma(selectedLng)
                    }}
                    value={lenguaje}>
                      <option value="es">ESPAÑOL</option>
                      <option value="en">ENGLISH</option>
                      <option value="ch">中國人</option>
                      <option value="fr">FRANÇAIS</option>
                    </select>
                  </NavLng>
                  
                  <NavRightSaldo>
                    {dataValues.currencySymbol} {dataValues.balance}
                  </NavRightSaldo>
                  <div>
                    <Button content={t("header.mi-cuenta")} btnstyle='btn-secondary' clicked={goToBackoffice}/>
                  </div>
                  <Button content={t("header.recargar")} btnstyle='btn-danger' clicked={openModalRecarga}/>
                  <NavSalir  onClick={goLogout}>
                    
                    <img src={salir} alt="" width="30"/>
                  </NavSalir>
                </>
                :<>
                  <NavLng>
                    <p>{t("header.lenguaje")}</p>
                    <select onChange={(e)=>{
                      const selectedLng=e.target.value;
                      setLenguaje(selectedLng)
                      i18n.changeLanguage(selectedLng)
                      setIdioma(selectedLng)
                    }}
                    value={lenguaje}>
                      <option value="es">ESPAÑOL</option>
                      <option value="en">ENGLISH</option>
                      <option value="ch">中國人</option>
                      <option value="fr">FRANÇAIS</option>
                    </select>
                  </NavLng>  
                  <NavLinks to='/'>
                    <Button content={t("header.login")} btnstyle='btn-secondary' clicked={openModalLogin}/>
                  </NavLinks>
                  
                </>
              }
            </NavRightMenu>

          </NavMenuContent>
        </Nav>
      </IconContext.Provider>

      <Modal ref={modalRecarga}>
        <MetodoPago modalRecarga={modalRecarga}/>
      </Modal>

      <Modal ref={modalLogin}>
        <ModalContent width={'70%'}>
          <ModalHeader>
              <ModalTitle>{t("popup-login.signup")}</ModalTitle>
              <FaIcons.FaTimes onClick={() => modalLogin.current.close()}/>
          </ModalHeader>
          <ModalBody>
              <LoginPage/>
          </ModalBody>
        </ModalContent>
      </Modal>

    </>
  );
}

export default Navbar
