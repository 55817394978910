import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Slider from '../Components/Carousel/Slider'
import Footer from '../Components/Footer/Footer'
import Navbar from '../Components/NavBar/Navbar'
import PageWrapperStyled from '../Components/PageWrapper'
import CasinoEnVivo from './CasinoEnVivo'
import Mesas from './Mesas'
import OtrosJuegos from './OtrosJuegos'
import Tragamonedas from './Tragamonedas'

const Main = () => {

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
   }
  return (
    <React.Fragment>
      <Navbar />
      <Slider />
      <PageWrapperStyled>
      <Switch>
        <Route path='/casino-en-vivo' key={makeid(6)} component={CasinoEnVivo}/>
        <Route path='/mesas' key={makeid(6)} component={Mesas}/>
        <Route path='/otros-juegos' key={makeid(6)} component={OtrosJuegos}/>
        <Route path='/' key={makeid(6)} exact component={Tragamonedas}/>
      </Switch>
      </PageWrapperStyled>
      <Footer />
    </React.Fragment>
  )
}

export default Main